export const MODULE_PROGRESS_STATUS = {
	INACTIVE: 'INACTIVE',
	ACTIVE: 'ACTIVE',
	COMPLETED: 'COMPLETED',
};

export const PERSONALITY_TYPE = {
	CEO: 'ceo',
	CTO: 'cto',
	PM: 'pm',
	KREATIV: 'kreativ',
};

export const PERSONALITY_TYPE_HUN = {
	ceo: 'CEO',
	cto: 'CTO',
	pm: 'PM',
	kreativ: 'Kreatív',
};

export const FALLBACK_IMG_URL = './images/default_profile_picture.png';

export const URL_PREFIXES = {
	AUTH: '/auth',
	PERSONALITY: '/personality-test',
	ONBOARDING: '/onboard',
	STUDENT: '/student',
	MENTOR: '/mentor',
	OFFICE: '/office',
	UNIVERSITY: '/university',
} as const;

export const USER_TYPES = {
	STUDENT: 'STUDENT',
	MENTOR: 'MENTOR',
	OFFICE: 'OFFICE',
	UNIVERSITY: 'UNIVERSITY',
};

export enum PROJECT_ROLES {
	OWNER = 'OWNER',
	MEMBER = 'MEMBER',
}

export const LAYOUT_TYPES = {
	GUEST: 'GUEST',
	PERSONALITY: 'PERSONALITY',
	ONBOARDING: 'ONBOARDING',
	ENROLLED: 'ENROLLED',
	OFFICE: 'OFFICE',
	UNIVERSITY: 'UNIVERSITY',
	MENTOR: 'MENTOR',
} as const;

export const FILE_MAX_NUMBER = {
	corporateProblem: 3,
};

export enum PROJECT_STATUS {
	NOT_CREATED = 'NOT CREATED',
	CREATED = 'CREATED',
	DRAFT = 'DRAFT',
	PUBLISHED = 'PUBLISHED',
}
