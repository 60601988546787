export default {
	header: {
		title: 'Modulok',
		subtitle: {
			start: 'A félév során',
			modules: 'modulon',
			end: 'keresztül tanulhatsz a sikeres startup vállalkozóvá válásról.',
		},
	},
	testAttempt: {
		start: 'még ',
		end: 'próbálkozásod van',
		zero: 'nincs több próbákozási lehetőséged',
	},
	bonus: 'bónusz',
};
