import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { getUser } from 'app/store/slices/auth.slice';
import { genericRefreshToast } from 'app/store/toast';

const initialState = {
	isPending: false,
	progressReports: null,
	progressReportsUploadCount: null,
	pagination: {
		totalCount: null,
		pageCount: null,
		pageNumber: 1,
	},
};

export const listProgressReports = createAsyncThunk(
	'list-progress-reports',
	async (params = {}, thunkApi) => {
		const { getState, dispatch } = thunkApi;
		const { pageNumber, initialLoad } = params;

		const state = getState();
		const progressReportState = state.progressReportList;
		const user = getUser(state);

		// prevent multiple search requests
		if (progressReportState.isPending) {
			return null;
		}

		// block further searches until action finishes
		// might skip on initial load
		if (!initialLoad) {
			dispatch(startPending());
		}

		try {
			const requestBody = {
				pageNumber: pageNumber ?? progressReportState.pagination.pageNumber,
			};

			const role = user.role.toLowerCase();
			const response = await axiosService.instance.post(
				`/progress-report/${role}`,
				requestBody
			);

			const { progressReports, progressReportsUploadCount, pagination } = response.data;

			return {
				progressReports,
				progressReportsUploadCount,
				pagination,
			};
		} catch (error) {
			genericRefreshToast();
			throw error;
		}
	}
);

export const progressReportListSlice = createSlice({
	name: 'progressReportList',
	initialState,
	reducers: {
		startPending(state) {
			state.isPending = true;
		},
	},

	extraReducers: {
		[listProgressReports.fulfilled]: (state, action) => {
			// cancelled request
			if (action.payload === null) {
				return;
			}

			const { progressReports, progressReportsUploadCount, pagination } = action.payload;

			state.isPending = false;
			state.pagination = pagination;
			state.progressReports = progressReports;
			state.progressReportsUploadCount = progressReportsUploadCount;
		},
	},
});

export const { startPending } = progressReportListSlice.actions;

export const selectProgressReportListPaginationInfo = (state) =>
	state.progressReportList.pagination;

export const selectProgressReportListResultSize = (state) =>
	state.progressReportList.pagination.totalCount ?? null;

export const selectProgressReportListIsPending = (state) => state.progressReportList.isPending;
export const selectProgressReportList = (state) => state.progressReportList.progressReports;

export const selectProgressReportUploadedRate = (state) => ({
	totalCount: state.progressReportList.pagination.totalCount,
	uploadedCount: state.progressReportList.progressReportsUploadCount,
});

export default progressReportListSlice.reducer;
