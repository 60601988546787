import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles({
	root: {
		boxShadow: 'none',
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'center',
	},
});

const GuestTopbar = () => {
	const classes = useStyles();

	return (
		<AppBar className={classes.root} color="primary">
			<Toolbar className={classes.toolbar}>
				<RouterLink to="/">
					<img alt="Logo" src="./images/logo.svg" />
				</RouterLink>
			</Toolbar>
		</AppBar>
	);
};

GuestTopbar.defaultProps = {
	profile: false,
};

export default GuestTopbar;
