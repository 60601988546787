export default {
	title: 'Hírfolyam',
	subtitle: 'Hírek, határidők, emlékeztetők és minden amit tudnod kell. Üdv a HSUP hírfolyamban!',
	addNews: {
		title: 'Szeretnél egy fontos információt megosztani a diákokkal?',
		subtitle: 'Kattints az "új hír feltöltése" gombra!',
		publishButton: 'Új hír feltöltése',
	},
	noNews: 'Jelenleg nincsenek hírek a hírfolyamban',
	deleteDialog: {
		title: 'Biztos törölni szeretnéd a hírt?',
		subtitle:
			'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Curabitur blandit tempus porttitor.',
		submitButton: 'Hír törlése',
	},
	dialog: {
		subtitle:
			'Itt le kellene írni, hogy milyen fajta híreket érdemes az adminoknak feltölteni,akár rövid példákkal.',
		newsDataFormGroupTitle: 'Hír adatai',
		newsTitle: 'Hír címe',
		newsTitlePlaceholder: 'Kedves diákok...',
		newsContent: 'Hír szövege',
		newsContentPlaceholder: 'Kedves diákok...',
		newsPicture: 'Kép feltöltése (opcionális)',
		newsPictureFieldTitle: 'A hírhez tartozó kép',
		newsPictureFieldSubtitle: 'Kattints ide vagy húzd ide a képet a feltöltéshez!',
		newsPictureFormatText: 'Feltölthető formátumok:',
		newsFilterFormGroupTitle: 'Kinek szeretné kiküldeni a hírt?',
		newsFilterFormGroupSubtitle:
			'A hírt csak az a felhasználói csoport fogja megkapni akiket itt megjelöl.',
		newsChooseUniversity: 'Egyetem választása',
		newsChooseRole: 'Felhasználó típus választása',
		sendToMentors: 'Kiküldés mentoroknak',
	},
	createDialog: {
		title: 'Új hír feltöltése',
		submitButton: 'Hír közzététele',
	},
	updateDialog: {
		title: 'Hír szerkesztése',
		submitButton: 'Módosítások publikálás',
	},
	toast: {
		addError: 'A hír feltöltése nem sikerült :(',
		addSuccess: 'A hír feltöltése sikerült',
		updateError: 'A hír módosítása nem sikerült',
		updateSuccess: 'A hír módosítása sikerült',
		deleteError: 'A hír törlése nem sikerült',
		deleteSuccess: 'A hír törlése sikerült',
	},
	rolesPlural: {
		student: 'Diákok',
		university: 'Tantárgyfelelősök',
		mentor: 'Mentorok',
	},
	validation: {
		checkboxGroupNoRolesChosen: 'Legalább egy felhasználó csoportot kikell választani',
	},
};
