export default {
	title: 'One pager',
	admin: {
		pageTitle: {
			onePager: 'One-pager',
			corporateProblems: 'Vállalati problémák',
		},
		title: 'Leadott one-pagerek',
		subtitle:
			'Itt tudod letölteni a hallgatók által leadott one-pagereket. A jobb oldali szűrőnél van lehetőséged az egyes egyetemek hallgatóinak saját problémáira illetve vállalati problémákra szűrni.',
		rate: {
			start: 'Eddig',
			student: 'diák',
			end: 'töltött fel one-pagert.',
		},
		list: {
			studentName: 'Név',
			type: 'Típusa',
		},
		ownProblem: 'Saját probléma',
		notUploaded: 'Nincs feltöltve',
		download: 'Letöltés',
	},
	student: {
		title: 'One pager',
		subtitle:
			'Válassz az alábbi problémák közül egyet és dolgozz ki rá egy lehetséges megoldást one-pager formájában!',
		progress: {
			notCompletedTitle: 'Először vegézd el az összes modult!',
			notCompletedContent:
				'Ahhoz, hogy one-pagert tölthess fel, fejezd be a tananyagot és töltsd ki az összes modulhoz tartozó vizsgát.',
			alreadyCompletedTitle: 'Gratulálunk! Ebben a félévben már adtál le one-pagert!',
			alreadyCompletedContent: 'A leadás dátuma: {{submittedDate}}',
		},
		ownOnePager: {
			title: 'Egyéni one-pager feltöltése',
			oneliner:
				'Annyira szuper vállalkozási ötleted van, hogy nem is keresgélsz a vállalati problémák között? Remek, kattints a feltöltéshez!',
		},
		detailCard: {
			descriptionTitle: 'Kihívás leírása',
			docsListTitle: 'Kapcsolódó dokumentumok',
			freeSeatText: 'Még {{count}} db hely szabad',
			submitSection: {
				title: 'Tetszik a vállalati probléma?',
				content: 'Add le a one-pagered a vállalati problémára!',
				buttonLabel: 'One-pager leadása',
			},
			own: {
				title: 'Egyéni one-pager feltöltése',
				description: `A félév végén egy onepager leadására van lehetőséged, azaz döntened kell, hogy a saját ötletedet mutatod be, vagy egy vállalati kihívásra hozol megoldást. Amennyiben a tárgyat a következő félévben is folytatod és kiválasztják a one-pageredet, akkor a most leadott ötleteden fogsz dolgozni és csapatot alakítani. 

A One-pager, másnéven „befektetői összefoglaló”, jellemzően az első dokumentum, amit a leendő befektetőd kézhez kap a projektedről.

Ilyen egy jó one-pager:

- Az egész üzleti lehetőséget, koncepciót összefoglalja,

- kész, megalapozott és kidolgozott ötlet alapján áll össze;

- maximum 1 oldal terjedelmű, a teljes leírás és minden elem tömören jelenik meg benne;

- célja a figyelem megragadása, egyedi, ezért igényes megjelenéssel bír;

- elsősorban az üzleti potenciált és a nagy megtérülési lehetőséget mutatja be, a megoldás technikai háttere nem kerül előtérbe;

- a startup aktuális állapota és előrehaladása mellett tartalmazza azt is, hogy mit kér a csapat a megkeresett befektetőtől (finanszírozási igény).`,
			},
		},
		listItem: {
			freeSeats: 'szabad hely',
			nextBtn: 'Tovább a problémára',
		},
		dialog: {
			upload: {
				title: 'One-pager feltöltése',
				subtitlePostFix: 'vállalati probléma',
				personal: 'Egyéni',
				fileTitle: 'one-pager feltöltése',
				uploadPlaceholder: 'Kattints ide vagy húzd ide a fájlt a feltöltéshez!',
				formatsText: 'Feltölthető formátumok:',
				upload: 'one-pager feltöltése',
			},
			confirm: {
				title: 'Biztos vagy benne, hogy végleg leadod a one-pagered?',
				subtitle: 'Ne feledd!',
				text: 'Csak egy one-pagert tölthetsz fel, a saját ötleted one-pagerét vagy egy vállalati probléma megoldásra kidolgozott vállalati one-pagert. A one-pageredet később már nem tudod módosítani.',
			},
		},
		onboarding: {
			title: 'One-pager',
			subtitle: 'Minden, amit a onepagerről tudni érdemes.',
			cards: {
				1: {
					title: 'One-pager',
					text: 'Miután a teszteket kisujjból kiráztad, a félév zárásaként egy vállalkozási ötlet egy oldalas bemutatóját kell feltöltened a felületre. Bemutathatod a saját ötletedet, vagy dönthetsz úgy is, hogy a HSUP partnereinek kihívásaira hozol megoldást.',
					imageAlt: 'design',
				},
				2: {
					title: 'A one-pager felépítése',
					text: 'A onepagered elkészítésekor a tartalmi elemekkel és a onepager felépítésével kapcsolatban az erre vonatkozó tananyagrészben találsz segítséget.',
					imageAlt: 'list',
				},
				3: {
					title: 'One-pager a saját ötletedről',
					text: 'Miután végeztél a modulzáró vizsgákkal, egy onepagert kell leadnod a félév sikeres elvégzéséhez. Amennyiben van egy saját vállalkozási ötleted, válaszd az egyéni onepager feltöltése funkciót és mutasd be saját ötletedet!',
					imageAlt: 'idea',
				},
				4: {
					title: 'Vállalati one-pager',
					text: 'Amennyiben a modulzáró vizsgák után úgy döntesz, hogy nem a saját ötletedet szeretnéd bemutatni egy onepagerben, nézz szét a vállalati kihívások között a onepager menüpontban. Ha van egy jó ötleted az egyik kihívás megoldására, készíts róla onepagert és töltsd fel a választott kihíváshoz.',
					imageAlt: 'corporate',
				},
				5: {
					title: 'Figyelem!',
					text: 'A félév végén egy onepager leadására van lehetőséged, azaz döntened kell, hogy a saját ötletedet mutatod be, vagy egy vállalati kihívásra hozol megoldást. Amennyiben a tárgyat a következő félévben is folytatod és kiválasztják a one-pageredet, akkor a most leadott ötleteden fogsz dolgozni és csapatot alakítani.',
					imageAlt: 'attention',
				},
			},
		},
	},
	sidebar: {
		structure: {
			title: 'One-pager felépítése',
			required: {
				title: 'Kötelező elemek',
				oneLine: '"Egysoros" – bemutatkozás egy mondatban',
				problem: 'Feltárt probléma',
				solution: 'Megoldásod a problémára',
				market: 'Piac bemutatása',
				competitor: 'Versenytársak, elemzések',
				corporate: 'Vállalati versenyképesség',
				business: 'Üzleti modell',
				founders: 'Az alapítók és a csapat',
			},
			bonus: {
				title: 'Bónusz elemek',
				marketing: 'Marketing stratégia',
				finance: 'Finanszírozás, felhasználás',
				milestones: 'Mérföldkövek',
			},
		},
		dontForget: {
			title: 'Ne feledd!',
			content:
				'Csak egy one-pagert tölthetsz fel, a saját ötleted one-pagerét vagy egy vállalati probléma megoldásra kidolgozott vállalati one-pagert.',
		},
		uploadSteps: {
			title: 'One-pager felöltés lépései',
			steps: {
				choose: 'Válassz a vállalati kihívások közül, vagy hozz saját vállalkozási ötletet!',
				make: 'Készítsd el a One-pageredet a problémára!',
				upload: 'Töltsd fel a kész one-pageredet a megfelelő helyre!',
			},
		},
	},
	toast: {
		addError: 'A one-pager feltöltése nem sikerült :(',
	},
	successModal: {
		title: 'Sikeres feltöltés',
		text: 'Gratulálunk! A one-pageredet sikeresen feltöltötted a félévre!',
	},
};
