import palette from '../palette';

export default {
	root: {
		fontSize: '14px',
		fontWeight: 700,
		lineHeight: '16px',
		boxShadow: 'none',
		borderRadius: 40,
		padding: '12px 18px',
		border: '2px solid transparent',
		height: '40px',
	},
	sizeLarge: {
		fontSize: '14px',
		fontWeight: 700,
		lineHeight: '16px',
		padding: '22px',
	},
	contained: {
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
		},
	},
	outlinedPrimary: {
		padding: '12px 18px',
		borderColor: palette.primary.main,
		borderWidth: 2,
		'&:hover': {
			borderWidth: 2,
		},
	},
};
