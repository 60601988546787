import palette from '../palette';

export default {
	root: {},
	notchedOutline: {
		borderColor: 'rgba(0,0,0,0.15)',
	},
	input: {
		color: palette.text.secondary,
		'&::placeholder': { color: palette.text.primary },
	},
};
