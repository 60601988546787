import { Drawer, Hidden, List, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { NestedListItem, SidebarListItemLogout, SimpleListItem } from './ListItem';
import useStyles from './Sidebar.styles';

const Sidebar = ({ openMobile, onMobileClose, currentNavbarItems }) => {
	const classes = useStyles();

	const renderNavbarItems = () => {
		return currentNavbarItems.map((item) => {
			const isNestedListItem = !item.href;
			const ListItemComp = isNestedListItem ? NestedListItem : SimpleListItem;

			return <ListItemComp key={item.titleKey} item={item} onNavigation={onMobileClose} />;
		});
	};

	const navbarContent = (
		<div className={classes.content}>
			<div className={classes.logoContainer}>
				<RouterLink to="/" className={classes.logoLink}>
					<img alt="Logo" src="./images/logo.svg" />
				</RouterLink>
			</div>
			<nav className={classes.navigation}>
				<List className={classes.sidebarList}>
					{renderNavbarItems()}
					<Hidden lgUp>
						<SidebarListItemLogout />
					</Hidden>
				</List>
			</nav>
		</div>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
					<div className={classes.root}>{navbarContent}</div>
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Paper className={classes.root} elevation={0} square>
					{navbarContent}
				</Paper>
			</Hidden>
		</>
	);
};

Sidebar.defaultProps = {
	onMobileClose: () => {},
	openMobile: false,
};

Sidebar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

export default Sidebar;
