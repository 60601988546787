export default {
	result: {
		title: 'Modulzáró vizsgaeredmény',
		error: 'Hiba történt :(',
		back: 'modulok',
		retry: 'Újrapróbálás',
		congratulations: 'Gratulálunk, sikeres vizsga!',
		score: 'Megszerzett pontok száma: {{score}} pont',
		failure: 'Sajnos ez most nem sikerült!',
		successDescription:
			'Szép munka! Egy igazi startupper vagy, nem csalódtunk benned. Ahogy kellett, mint kés a vajon. Ha a való életben is így veszed majd a kihívásokat, hamar üzleti magazinok címlapján találod magad. Nézz vissza az irányítópultra az előrehaladásod áttekintéséért, vagy lépj a következő tananyagra.',
		failureDescription:
			'Csak semmi pánik, startupperként néha nem úgy alakulnak a dolgok, ahogy szeretnénk. Itt nincsen olyan, hogy megbuktál, szerencsére az élet nem így működik. Tanulj a hibáidból, higgadj le, igyál egy teát, majd újult erővel ugorj neki még egyszer a tesztnek. Meg tudod csinálni, mi hiszünk benned!',
	},
	header: {
		title: 'VIZSGA',
	},
	navigation: {
		next: 'Következő',
		back: 'Vissza',
		submit: 'Leadás',
	},
	errors: {
		semesterEnded: 'A félév véget ért.',
		semesterEndedDescription:
			'Eredményeidet és ajánlott jegyedet megtekintheted az irányítópulton.',
		moduleInactive: 'A vizsgához tartozó modult még nem oldottad fel.',
		moduleInactiveDescription:
			'De ne aggódj, mert az ezt megelőző modulok tesztjének kitöltésével könnyedén feloldhatod. Sok sikert!',
		outOfAttempts: 'Elfogytak a próbálkozásaid.',
		outOfAttemptsDescription:
			'Sajnos ez a teszt most nem jött össze. De fel a fejjel! A megszerzett pontjaid így is beleszámítanak az ajánlott jegyedbe. Hogy ne búslakodj, feloldottuk neked a következő modult. Vágj bele most!',
	},
	confirmation: {
		areYouSure: 'Biztos le szeretnéd adni a tesztet?',
		notAllAnsweredDescription:
			'Nekünk úgy tűnik, hogy maradtak még megválaszolatlan kérdéseid.',
		description:
			'Ha nem vagy biztos a válaszaidban ellenőrizd őket, vagy kattints a "Leadás most" gombra. Ne felejtsd el, a vizsga újbóli kitöltésének eredménye felülírja az ehhez a vizsgához tartozó már korábbi eredményedet!',
		decline: 'Vissza',
		confirm: 'Leadás most',
	},
};
