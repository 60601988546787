export default {
	fields: {
		username: 'Felhasználónév',
		email: 'E-mail cím',
		password: 'Jelszó',
		currentPassword: 'Jelenlegi jelszó',
		newPassword: 'Új jelszó',
		newPasswordVerification: 'Új jelszó megerősítése',
	},
	buttons: {
		login: 'Belépek',
	},
	pages: {
		login: {
			title: 'Bejelentkezés',
			noProfile: 'Még nincsen fiókod?',
			signInButton: 'Belépek',
			forgotPassword: 'Elfelejtettem a jelszavam',
			wrongCredentials: 'E-mail és jelszó páros nem megfelelő',
			networkError: 'Hiba a hálózati hozzáférésben. Ellenőrizd az internet kapcsolatot!',
			otherError: 'Hiba történt. Próbálkozz újra később!',
		},
		forgotPassword: {
			title: 'Elfelejtetted a jelszavad?',
			subtitle: 'Add meg az e-mail címed és könnyedén megadhatsz egy új jelszót.',
			successHeader: 'Jelszóemlékeztető kiküldve',
			successDescription:
				'Amennyiben regisztráltál a megadott e-mail címmel és a regisztrációt befejezted, emailt kapsz a további lépésekről.',
			resetPassword: 'Jelszó visszaállítása',
		},
		changePassword: {
			title: 'Jelszó módosítása',
			editPassword: 'Jelszó Módosítás',
			successHeader: 'Jelszó sikeresen módosítva!',
			successDescription: 'Most már be tudsz jelentkezni az új jelszóval.',
			failHeader: 'Sikertelen módosítás!',
			failDescription: 'Ez a link már lejárt.',
			failedButtonLabel: 'Vissza a főoldalra',
		},
		register: {
			title: 'Regisztráció',
			registerAction: 'Regisztrálok',
			successHeader: 'Sikeres regisztráció',
			successDescription: 'Üdvözlünk a HSUP rendszerében, most már be tudsz jelentkezni.',
			failHeader: 'Sikertelen regisztráció!',
			failDescription: 'Ez a link már lejárt.',
			failedButtonLabel: 'Vissza a főoldalra',
		},
	},
};
