const mentorI18nHu = {
	findMentor: {
		header: {
			title: 'Mentor kereső',
			STUDENT: {
				subtitle:
					'A mentor kiválasztása funkcióval csak a projektgazda rendelkezik. Minden csapatnak egy mentora lehet. Több mentorhoz is adhattok le jelentkezést, de az lesz a csapat mentora, aki a jelentkezést először elfogadja.',
			},
			OTHER: {
				subtitle: 'A programban részt vevő mentorok',
			},
		},
		noResult: {
			text1: 'A megadott feltételekkel nem találtunk mentort.',
			text2: 'Kérjük bővítsd a szűrési feltételeket.',
		},
	},
	mentorApplications: {
		header: {
			title: 'Jelentkezéseim',
			subtitle: 'Mentorok akikhez jelentkeztél.',
		},
		emptyState: {
			title: 'Még nem jelentkeztél mentorhoz',
			subtitle:
				'Nézz körül a mentorlistában, nehogy elhappolják előled a számodra legmegfelelőbb mentort. Fontos, hogy most csak 1 mentorod lehet, de több mentorhoz is adhatsz le jelentkezést. Az lesz a mentorod, aki a jelentkezésedet először elfogadja.',
			primaryButton: 'Mentor kereső',
		},
		hasMentor: {
			title: 'Gratulálunk, már van mentorod!',
			subtitle: 'Elérhetőségeit megtalálod a Csapat menüpontban.',
			primaryButton: 'Megnézem a csapatom',
		},
		card: {
			revokeButton: 'jelentkezés visszavonása',
			fullButton: 'a mentor betelt',
		},
		revokeDialog: {
			title: 'Biztos vissza szeretnéd vonni a jelentkezést?',
			subtitle:
				'Ha visszavonod a jelentkezést, az eltűnik a mentor számára a platformon, így később már nem tudja elfogadni.',
			cancel: 'Mégsem',
			submit: 'Visszavonás',
		},
	},
	manageMentor: {
		acceptDialog: {
			title: 'Biztosan elfogadod a felkérést?',
			subtitle:
				'Egy projektcsapatnak csak egy mentora lehet, így ha elfogadod a felkérést, akkor a csapat már nem fog tudni ezen változtatni.',
			cancel: 'Mégsem',
			submit: 'Elfogad',
		},
		rejectDialog: {
			title: 'Biztosan elutasítod ezt a projektet?',
			subtitle:
				'Ha egyszer elutasítasz egy csapatot, többször nem fognak tudni jelentkezni hozzád.',
			placeholder: 'Indoklás (Maximum 1000 karakter)',
			cancel: 'Mégsem',
			submit: 'Elutasít',
		},
	},
	applyMentor: {
		title: 'Mentor jelentkezés',
		lead: 'A mentor meg tudja tekinteni a projekted adatlapját, de személyes üzenetet is megfogalmazhatsz számára. A jelentkezés alapján a mentor elfogadhatja vagy elutasíthatja a jelentkezésedet. Mielőtt véglegesíted a jelentkezést, ne feledd, csak egy mentort választhatsz a csapatodnak!',
		motivation: {
			title: 'Mutasd be magad és a csapatodat!',
			label: 'Miért gondolod, hogy a kiválasztott mentor lenne számodra a legmegfelelőbb? Oszd meg a motivációdat.',
			placeholder: 'Kezdj el gépelni',
		},
		submit: 'Jelentkezem a mentorhoz',
		applySuccessMessage: 'Sikeres jelentkezés!',
		applyBeforeProject: 'Először publikáld projekted leírását!',
		alreadyHasMentor: 'Ennek a projektnek már van mentora, kérlek válassz másikat!',
		revokeSuccessMessage: 'Sikeres visszavonás!',
	},
};

export default mentorI18nHu;
