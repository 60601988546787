const memberI18nHu = {
	list: {
		header: {
			title: 'Csapattag kereső',
			STUDENT: {
				subtitle: 'Találd meg a csapatodba legjobban illő hallgatókat!',
			},
			OTHER: {
				subtitle: '',
			},
		},
		noResult: {
			text1: 'A megadott feltételekkel nem találtunk hallgatót.',
			text2: 'Kérjük bővítsd a szűrési feltételeket.',
		},
		apply: {
			title: 'Biztos meghívod a hallgatót a csapatodba?',
			lead: 'A meghívás nem visszavonható, amint a hallgató visszajelez a meghívással kapcsolatban értesíteni fogunk!',
			submit: 'Meghívom a csapatomba',
		},
		applySuccess: {
			title: 'Sikeres meghívás',
			lead: 'Amint a hallgató visszajelez a jelentkezéseddel kapcsolatban értesíteni fogunk.',
			button: 'Rendben',
		},
	},
	details: {
		header: {
			title: 'Csapattag ',
		},
		card: {
			title: 'Gratulálunk, már van mentorod!',
			subtitle: 'Elérhetőségeit megtalálod a Csapat menüpontban.',
			primaryButton: 'Megnézem a csapatom',
		},
		button: {
			invite: 'Meghívom a csapatomba',
			back: 'Csapattag kereső',
		},
		sections: {
			shortIntroduction: 'Rövid bemutatkozás',
			aboutMe: 'Magamról',
			interests: 'Érdeklődési terület',
			personality: 'Személyiségtípus',
		},
	},
	invited: {
		header: {
			title: 'Meghívások',
			subtitle:
				'Itt láthatod azokat a projekteket amiknek a projektfelelőse szeretné, hogy csatlakozz a csapatába',
		},
		noResult: {
			title: 'Nincsenek meghívások',
			lead: 'Amennyiben szimpatikusnak tartasz egy hallgatót a profilja alapján, meghívhatod csapatodba, hogy együtt dolgozzatok a félév során. Nézz körül a hallgató keresőben és találd meg a legalkalmasabb jelölteket!',
			button: 'Hallgató kereső',
		},
	},
	invitations: {
		header: {
			title: 'Meghívások',
			subtitle:
				'Itt láthatod azokat a projekteket amiknek a projektfelelőse szeretné, hogy csatlakozz a csapatába',
		},
		noResult: {
			title: 'Nincsenek meghívások',
			lead: 'Amennyiben szimpatikusnak tart egy projekfelelős a profilod alapján meghívhat a csapatába. Ezek a projektek ahova kaptál meghívást itt fognak megjelenni.',
		},
	},
};

export default memberI18nHu;
