export default {
	firstSemester: {
		semester: 'I. félév',
		topics: {
			1: {
				title: 'Üdvözlünk a Hungarian Startup University Programban.',
				alt: 'blogging',
				text: 'Kérjük, hogy a következő rövid bevezetőt figyelmesen olvasd el, hogy megismerd a félév legfontosabb tudnivalóit. Ezeket a továbbiakban a segítség menüpont alatt találod majd.',
			},
			2: {
				title: 'A tananyag',
				alt: 'relax_sleep',
				text: 'A HSUP félévének tananyaga 7 modulból amikben megismerkedhetsz a startupok világával. A modulokban bónusz tananyagokat is találsz amelyek bár nem kötelezőek, erősen ajánlottak. Itt ismerkedhetsz meg olyan üzleti praktikákkal, amelyekkel felgyorsíthatod a startupod növekedését.',
			},
			3: {
				title: 'Tesztek',
				alt: 'support',
				text: 'A HSUP nem egy hagyományos egyetemi kurzus, így a tesztektől sem kell megijedni. Fordíts rájuk elég időt, olvasd végig a tananyagot és akkor biztos nem érhet meglepetés!',
			},
			4: {
				title: 'One-pager',
				alt: 'one-pager',
				text: 'Miután a teszteket kisujjból kiráztad, a félév zárásaként egy vállalkozási ötlet egy oldalas bemutatóját kell feltöltened a felületre. Bemutathatod a saját ötletedet, de dönthetsz úgy is, hogy a HSUP partnereinek kihívásaira hozol megoldást.',
			},
			5: {
				title: 'Irányítópult',
				alt: 'plan',
				text: 'Az irányítópulton az eddig elért eredményeidet és a kurzusban való előrehaladásodat láthatod. Kövesd az eredményeket hétről-hétre és folytasd a tananyagokat a saját tempódban!',
			},
			6: {
				title: 'Teljesítési idő',
				alt: 'time',
				text: 'A tananyag kitöltése becsléseink szerint, modulonként körülbelül 2 órát, összesen 10-15 órát vesz igénybe egyéni haladási sebességednek megfelelően. Javasoljuk, hogy ezt a mennyiséget ne az utolsó napra tartogasd, de erről mi is értesítünk majd. Emellett azt is láthatod majd, hogy az egyes modulokat mikor oldjuk majd fel folyamatában így te is szakaszosan tudsz majd haladni.',
			},
			7: {
				title: 'A Startup Labor a lehetőségek tárháza.',
				alt: 'discovery',
				text: 'A Startup Labor a lehetőségek tárháza. Nézd meg mi történik a hazai és nemzetközi startup szcénában. Szeretnél részt venni egy meetup-on? Jelentkeznél egy akszelerációs programba? Esetleg egy nagyvállalati képzés érdekel? Látogass el a Startup Laborba rendszeresen, hiszen a lehetőségek folyamatosan frissülnek.',
			},
			8: {
				title: 'Versenyezz az egyetemedért!',
				alt: 'race',
				text: 'Ahogy a startupok világa is csapatverseny, úgy a HSUP keretein belül is törekedtünk arra, hogy ne csak egyedül birkózz meg a kihívásokkal.  Az egyetemi rangsor az egyetemek hallgatóinak átlagpontszámai alapján áll fel. Ennek megfelelően legyetek 30-an vagy 300-an a kurzuson, az arányok szerint a legjobb győzedelmeskedik!',
			},
			9: {
				title: 'Mobil applikáció',
				alt: 'mobile',
				text: 'Ahhoz, hogy bármikor könnyedén hozzáférj a tananyaghoz, töltsd le a HSUP applikációt az App Store-on vagy a Google Play Store-on keresztül és engedélyezd az értesítéseket, hogy mindig képben legyél az aktuális tudnivalókkal.',
			},
			10: {
				title: 'Kérdésed van?',
				alt: 'contact',
				text: 'A platform használata során előfordulhat, hogy valamilyen funkcióval kapcsolatban kérdésed vagy észrevételed van. Beragad egy gomb, nem nyílik meg az oldal vagy egyéb a használattal kapcsolatos problémát észlelsz ne habozz, keresd a HSUP ügyfélszolgálatát munkaidőben a hsup@nkfih.gov.hu e-mail címen, vagy a +36 1 795 9500 telefonszámon. Ránk számíthatsz!',
			},
			11: {
				title: 'Személyiség teszt',
				alt: 'discovery',
				text: 'Mielőtt nekiugranánk a tananyagnak, egy teszt segítségével megnézzük, mik a legnagyobb erősségeid. Hiszen a startup csapat játék, így fontos, hogy ismerjétek egymás legfontosabb készségeit.',
			},
		},
	},
	secondSemester: {
		semester: 'II. félév',
		topics: {
			owner: {
				1: {
					title: 'Üdvözlünk a HSUP második félévében!',
					alt: 'welcome',
					text: 'A HSUP előző féléve az új startup ötletetitek bemutatásáról szólt, a mostani félévben viszont az ötletek megvalósításán és a csapaton lesz a hangsúly!',
				},
				2: {
					title: 'Projekted',
					alt: 'projekt',
					text: 'A félév első lépéseként el kell készítened projekted leírását, hogy csapattagokat szerezhess. Amint kész vagy a leírással, a projektedet publikálnod kell a többi hallgató számára, hogy tudjanak a projektedbe jelentkezni.',
				},
				3: {
					title: 'Csapatod',
					alt: 'team',
					text: 'A csapatodban jelentkező hallgatókat te bírálod el, hogy szerinted beleillenek-e a projektedbe. Ha valaki a profilja alapján tökéletes csapattag lenne projektedhez akkor te is meghívhatod őt. Csapatod min. 3 és max. 5 főböl állhat. Ha egyszer valaki elfogadásra kerül csapatodba, már nem tudsz rajta változtani.',
				},
				4: {
					title: 'Mentorok',
					alt: 'mentor',
					text: 'Ahhoz, hogy csapatod sikere mégbiztosabb legyen, a félévi munkátokat egy mentor fogja segíteni. Mentorokhoz neked kell jelentkezned egy rövid motivációs levéllel, amivel meggyőzheted a mentort, hogy ő segítse a munkátokat a félévben.',
				},
				5: {
					title: 'Progress report',
					alt: 'report',
					text:
						'A félév végén egy un. progress reportot kell felöltened egy sablon alapján, amiben összegzitek a féléves munkátokat.\n\n' +
						'Feltöltés határideje:\n\n' +
						'#### {{deadline}}',
				},
				6: {
					title: 'Modulok',
					alt: 'modules',
					text: 'Az ötletek kidolgozása és a csapatépítés mellett a második félévben sem maradhat el az elméleti tudás elsajátítása. Ebben a félévben 5 modulon keresztül tanulhatsz a startupok világáról.',
				},
				7: {
					title: 'Vágjunk bele!',
					alt: 'go',
					text: 'Most, hogy már mindent tudsz a félévről vágjunk is bele! Sikerekben gazdag félévet kívánunk! 💪',
				},
			},
			member: {
				1: {
					title: 'Üdvözlünk a HSUP második félévében!',
					alt: 'welcome',
					text: 'A HSUP előző féléve az új startup ötletetitek bemutatásáról szólt, a mostani félévben viszont az ötletek megvalósításán és a csapaton lesz a hangsúly!',
				},
				2: {
					title: 'Projekt keresés',
					alt: 'projekt',
					text: 'A HSUP második félévében meg kell találnod csapatodat akikkel a félév során fogsz dolgozni. Nézz körül a projekt keresőben és válaszd ki a számodra legszimpatikusabb projektet!',
				},
				3: {
					title: 'Projekt jelentkezés',
					alt: 'application',
					text: 'Egyszerre több projektre is jelenkezhetsz, de csak egy projektcsapathoz tudsz csatlakozni. Ha több projekt jelentkezésed is van függőben, akkor abba a csapatba fogsz bekerülni, amelyik a csapat projektgazdája a jelentkezésedet először elfogadja.',
				},
				4: {
					title: 'Mentorok',
					alt: 'mentor',
					text: 'Ahhoz, hogy csapatod sikere mégbiztosabb legyen, a félévi munkátokat egy mentor fogja segíteni. Mentorokhoz a projektgazdák tudnak jelentkezni, de ebben te is segíthetsz neki! Nézz körül a mentorkeresőben és ha megtaláltad a megfelelő mentort tegyél javaslatot a projetfelelősnek.',
				},
				5: {
					title: 'Modulok',
					alt: 'modules',
					text: 'Az ötletek kidolgozása és a csapatépítés mellett a második félévben sem maradhat el az elméleti tudás elsajátítása. Ebben a félévben 5 modulon keresztül tanulhatsz a startupok világáról.',
				},
				6: {
					title: 'Vágjunk bele!',
					alt: 'go',
					text: 'Most, hogy már mindent tudsz a félévről vágjunk is bele! Sikerekben gazdag félévet kívánunk! 💪',
				},
			},
		},
	},
};
