import palette from '../palette';

export default {
	root: {
		border: '2px solid #BDBDBD',
		backgroundColor: 'white',
		borderRadius: 5,
		'&:hover': {
			backgroundColor: 'white',
		},
		'&$focused': {
			borderColor: palette.primary.main,
			backgroundColor: 'white',
		},
		'&$error': {
			borderColor: palette.error.main,
			backgroundColor: 'white',
		},
	},
	input: {
		'&::placeholder': {
			opacity: 0.7,
			color: palette.text.secondary,
		},
	},
};
