import { colors } from '@material-ui/core';
import palette from '../palette';
import typography from '../typography';

export default {
	root: {
		...typography.caption,
		display: 'table-cell',
		borderBottom: `1px solid ${palette.divider}`,
		borderTop: `1px solid ${palette.divider}`,

		'&:first-of-type': {
			borderLeft: `1px solid ${palette.divider}`,
			borderRadius: '4px 0 0 4px',
		},

		'&:last-of-type': {
			borderRight: `1px solid ${palette.divider}`,
			borderRadius: '0 4px 4px 0',
		},
	},
	head: {
		color: palette.secondary.main,
		lineHeight: '18px',
	},
	body: {
		color: colors.blueGrey[700],
	},
};
