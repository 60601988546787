export default {
	title: 'Vállalati problémák',
	subtitle:
		'Itt találod a vállalati problémákat. Az "Új probléma feltöltése" gombra kattintva bármennyi problémát fel tudsz tölteni a hallgatók számára. A vállalati problémákat a félév folyamán bármikor tudod szerkeszteni vagy törölni.',
	add: {
		title: 'Új vállalati probléma feltöltése',
		subtitle: 'Új probléma feltöltéséhez kattintson az “új probléma feltöltése” gombra!',
		publishButton: 'új probléma feltöltése',
	},
	dialog: {
		add: {
			title: 'Új vállalati probléma feltöltése',
			submitButton: 'vállalati probléma feltöltése',
		},
		update: {
			title: 'Vállalalti probléma szerkesztése',
			submitButton: 'Módosítás publikálása',
		},
		common: {
			subTitle: 'Alapadatok',
			title: {
				title: 'probléma címe',
				titlePlaceholder: 'Kedves diákok...',
			},
			logo: {
				logo: 'Vállalat logója',
				logoPlaceholder: 'Kattints a kép feltöltéséhez',
			},
			oneliner: {
				oneliner: 'probléma rövid összefoglalója (Max. {{count}} karakter)',
				maxLenght: 'Maximum {{count}} karaktert adhatsz meg',
				onelinerPlaceholder: 'Kedves diákok...',
			},
			description: {
				description: 'probléma szövege',
				descriptionPlaceholder: 'Kedves diákok...',
			},
			limit: {
				limit: 'jelentkező diákok maximális száma',
				limitPlaceholder: 'pl. 5',
			},
			docs: {
				docsTitle: 'Kapcsolódó dokumentumok',
				docsSubTitle: 'Kiválasztott dokumentumok',
				docsUploadButton: 'Új dokumentum feltöltése',
				docsUploadSubtitle: 'Kattints ide vagy húzd ide a dokumentumokat a feltöltéshez!',
				docsFormatText: 'Feltölthető formátumok : ',
			},
		},
	},
	deleteDialog: {
		title: 'Biztos törölni szeretnéd a vállalati problémát?',
		subtitle:
			'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Curabitur blandit tempus porttitor.',
		submitButton: 'Probléma törlése',
	},
	item: {
		freeSlots: 'Szabad helyek',
	},
	toast: {
		addError: 'A probléma feltöltése nem sikerült :(',
		addSuccess: 'A probléma feltöltése sikerült',
		updateError: 'A probléma módosítása nem sikerült',
		updateSuccess: 'A probléma módosítása sikerült',
		deleteError: 'A probléma törlése nem sikerült',
		deleteSuccess: 'A probléma törlése sikerült',
	},
};
