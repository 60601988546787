import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from 'app/store/axiosService';
import { getUser } from 'app/store/slices/auth.slice';
import { genericRefreshToast } from '../toast';

const initialState = {
	isPending: false,
	students: null,
	pagination: {
		totalCount: null,
		pageCount: null,
		pageNumber: 1,
	},
	filters: {
		search: '',
		universities: null,
	},
};

export const findStudents = createAsyncThunk('find-student/all', async (params = {}, thunkApi) => {
	const { getState, dispatch } = thunkApi;
	const { pageNumber } = params;

	const state = getState();
	const studentState = state.findStudent;
	const filters = params.filters ?? studentState.filters;

	// prevent multiple search requests
	if (studentState.isPending) {
		return null;
	}

	// if action triggered by filter update, save it in store
	// on page change, filters are sent from store
	if (params.filters) {
		dispatch(updateCachedFilters(params.filters));
	}

	// block further searches until action finishes
	dispatch(startPending());

	try {
		const search = filters?.search ?? null;
		const { universities } = filters;
		const user = getUser(state);
		const role = user.role.toLowerCase();

		const requestBody = {
			universities,
			search,
			pageNumber: pageNumber ?? studentState.pagination.pageNumber,
		};
		const response = await axiosService.instance.post(`/find-student/all/${role}`, requestBody);
		const { students, pagination } = response.data;

		return { students, pagination };
	} catch (error) {
		genericRefreshToast();
		throw error;
	}
});

export const findStudentSlice = createSlice({
	name: 'findStudent',
	initialState,
	reducers: {
		startPending(state) {
			state.isPending = true;
		},
		updateCachedFilters(state, action) {
			state.filters = action.payload;
		},
	},

	extraReducers: {
		[findStudents.fulfilled]: (state, action) => {
			// cancelled request
			if (action.payload === null) {
				return;
			}

			state.isPending = false;

			const { students, pagination } = action.payload;
			state.pagination = pagination;
			state.students = students;
		},
	},
});

export const { startPending, updateCachedFilters } = findStudentSlice.actions;

export const selectFindStudentList = (state) => state.findStudent.students;
export const selectFindStudentsPaginationInfo = (state) => state.findStudent.pagination;
export const selectFindStudentsResultSize = (state) =>
	state.findStudent.pagination.totalCount ?? null;
export const selectFindStudentIsPending = (state) => state.findStudent.isPending;

export default findStudentSlice.reducer;
