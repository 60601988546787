import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
	},
	progressBar: {
		position: 'fixed',
		left: 0,
		top: '0px',
		width: '100%',
		height: '2px',
	},
	topBar: {
		zIndex: 2,
		position: 'relative',
	},
	container: {
		display: 'flex',
		margin: '0',
		overflow: 'hidden',
		width: '100%',
		backgroundColor: 'white',
	},
	navBar: {
		zIndex: 3,
		width: 250,
		minWidth: 250,
		flex: '0 0 auto',
	},
	content: {
		overflowY: 'auto',
		flex: '1 1 auto',
		padding: theme.spacing(0, 4),
		[theme.breakpoints.down('sm')]: { padding: theme.spacing(0, 1) },
		backgroundColor: '#f5f6f9',
		minHeight: '100vh',
	},
	toolbar: {
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 0, 2, 0),
	},
	mainContainer: {
		padding: theme.spacing(1),
	},
}));

export default useStyles;
