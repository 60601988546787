export default {
	title: 'Profil beállítások',
	subtitle: 'Töltsd ki profiladataidat, hogy a projektgazdák jobban megismerhessenek!',
	profile: {
		emailField: 'E-mail cím',
		changeAvatarBtn: 'Profilkép módosítása',
	},
	changePassword: {
		title: 'Jelszó változtatás',
	},
	personality: {
		title: 'Személyiség',
		changePersonality: 'Személyiség megváltoztatása',
		successMessage: 'Sikeres változtatás!',
		errorMessage: 'Sikertelen változtatás!',
	},
	projectMember: {
		title: 'Bemutatkozás',
		subtitle: 'Töltsd ki profiladataidat, hogy a projektgazdák jobban megismerhessenek!',
		submit: 'Módosítások mentése',
		success: 'Módosítások mentve',
		fail: 'Sikertelen módosítás',
		introduction: {
			title: 'Bemutatkozás',
			oneLiner: 'Frappáns one-liner',
			introduction: 'Rövid bemutatkozás',
		},
		social: {
			title: 'Social media linkek',
			facebook: 'Facebook link',
			linkedin: 'Linkedin link',
		},
		focuses: {
			title: 'Érdeklődési kör kiválasztása',
			subtitle: 'Maximum 5-öt választhatsz!',
			caption: 'Kiválasztott elemek',
			focusSelectModal: {
				title: 'Érdeklődési kör kiválasztása',
				subtitle: 'Maximum 5-öt választhatsz!',
				submit: 'Módosítások mentése',
			},
			listFocuses: {
				empty: 'Nincs kiválasztott ipari fókusz',
			},
			openModalButton: 'Elemek kiválasztása',
		},
	},
	mentor: {
		title: 'Profil szerkesztése',
		contact: 'Kontakt információk',
		requiredFields: 'A *-al jelölt mezők kitöltése kötelező',
		saveChanges: 'Változtatások mentése',
		publishDialogTitle: 'Biztos vagy benne, hogy nem publikálod a profilod?',
		publishDialogDescription:
			'Ha a profil nem kerül publikálásra, nem fog megjelenni a mentorkeresőben. A projektfelelősök így nem tudnak majd jelentkezni hozzád.',
		publishDialogSaveNoPublish: 'Csak mentés',
		publishDialogSaveAndPublish: 'Publikálás és mentés',
		contactDescription:
			'A következő adatok csak azoknak a csapatoknak jelennek meg, akiknek elfogadtad a jelentkezését.',
		contactFacebook: 'Facebook',
		contactLinkedIn: 'LinkedIn',
		contactWeb: 'Weboldal',
		contactEmail: 'Email *',
		contactPhoneNumber: 'Telefonszám',
		bio: 'Szakmai biográfia *',
		bioDescription: 'Kérjük, röviden mutasd be szakmai munkásságodat.',
		bioTitle: 'Titulus, pozíció, megjelenítendő szervezet *',
		bioTitleDescription:
			'Kérjük, rövid formában add meg azt a titulust, pozíciót vagy szervezetet amelyet neved alatt meg szeretnél jeleníteni.',
		specialization: 'Szakterület kiválasztása',
		specializationDescription: 'Egyszerre többet is választhatsz!',
		focus: 'Ipari fókusz kiválasztása',
		focusDescription: 'Egyszerre többet is választhatsz!',
		publish: 'Publikálás',
		publishDescription: 'Profil megjelenítése a mentor keresőben',
	},
};
