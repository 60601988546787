export default {
	title: 'Progress report',
	subtitle: 'A progress reportban foglalják össze a projektgazdák a félév munkáját.',
	whatIsProgressReport: 'Mi is az a progress report?',
	description:
		'A progress report egy írásos dokumentum amely összefoglalja a csapatok második féléves tananyagának feladatait, kiegészítve a személyes benyomásaikkal. Ebben a dokumentumban kell majd beszámolnia a csapatnak arról is, hogy honnan indultak és hova jutottak a félév során. A reportot a projektgazda tudja feltölteni a HSUP felületre, miután elvégezte az összes modult.',
	stepsTitle: 'Progress report feltöltése',
	stepsSubtitle: 'A progress report feltöltésének határideje:',
	deadline: '2021.05.30.',
	didYouKnowTitle: 'Tudtad?',
	didYouKnowDescription:
		'A progress reportot minden esetben a projekt ötletgazda tárgyfelelőse fogja megkapni abban az esetben is, ha a csapat tagjai különböző egyetemről érkező hallgatókból állnak.',
	steps: {
		1: 'Töltsd le a HSUP Progress report sablont!',
		downloadTemplate: 'Sablon letöltése',
		2: 'Egészítsd ki a sablon üres részeit a félév munkáját összefoglalva!',
		3: 'Töltsd fel a kitöltött sablont!',
		4: 'Kattints a "REPORT FELTÖLTÉSE" gombra!',
		uploadHere: 'Kattints a feltöltéshez, vagy húzd ide a fájlt!',
		acceptedExtensions: 'A fájlt pdf formátumban töltsd fel',
		uploadReport: 'REPORT FELTÖLTÉSE',
	},
	alreadyUploaded: {
		title: 'A Progress reportot sikeresen feltöltötted!',
		description: 'Az alábbi gombra kattintva tekintheted meg a feltöltött változatot.',
		buttonLabel: 'report letöltése',
	},
	completeSubjectFirst: {
		title: 'Először végezd el az összes modult!',
		description:
			'Jelenleg a Progress Reportot nem tudod feltölteni. Végezd el az összes modult és gyere vissza!',
	},
};
