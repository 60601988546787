export default {
	header: {
		welcome: 'Üdv, ',
		zeroModulesCompleted: {
			text: 'Kattints az első modulra és kezd el a kurzust!',
			link: '',
		},
		someModulesCompleted: {
			text: 'Néhány modul még kitöltésre vár.',
			link: 'Csináld meg őket most!',
		},
		allModulesCompleted: {
			text: 'Minden modult elvégeztél. Szép munka!',
			link: '',
		},
		secondSemester:
			'Ebben a félévben is vár rád 5 modul. Na meg a csapat, mentorok és szuper lehetőségek! Sok sikert!',
		universitySubtitle:
			'Köszöntünk a Hungarian Startup University Programban. Kövesd egyetemed és hallgatóid eredményeit!',
		officeSubtitle: 'Egy, aki mindent lát.',
	},
	performance: {
		title: 'Teljesítményed',
		suggestedGrade: 'Javasolt eredményed',
		text: {
			start: 'Végső eredményed',
			end: ', ne felejtsd el a one-pageredet elküldeni a tantárgy teljesítéséhez.',
		},
		suggestedText:
			'Amennyiben maradt még próbálkozási lehetőséged, javíthatsz a jegyeden a félév zárásáig.',
		grades: {
			5: 'jeles',
			4: 'jó',
			3: 'közepes',
			2: 'elégséges',
			1: 'elégtelen',
		},
		sum: 'Összesen',
		moduleProgress: {
			completed: 'Teljesítve',
			notCompleted: 'Nincs teljesítve',
			notAvailable: 'Nem elérhető',
		},
		body: {
			start: 'Eddig',
			points: 'pontot',
			middle: 'szereztél',
			end: 'modul teljesítésével.',
		},
	},
	progress: {
		title: 'Előrehaladás',
		modules: 'Teljesített modulok',
		onePager: 'One-pager beküldése',
		progressReport: 'Progress report beküldése',
		send: 'Küldés',
		moreInfo: 'További információ',
		chart: {
			completed: 'Teljesített modulok',
			remaining: 'Modulok száma',
		},
	},
	mentor: {
		headline: 'Mentorra van szükséged?',
		subtitle: 'Jól jönne egy kis segítség? Összekötünk a mentoroddal!',
		contact: 'Kapcsolatfelvétel',
	},
	personality: {
		text: {
			start: 'Te egy született',
			end: 'vagy',
		},
		ceo: 'CEO',
		cto: 'CTO',
		pm: 'PM',
		kreativ: 'Kreatív',
		learnMore: 'Tudj meg többet',
	},
	userStatistics: {
		completedExams: 'Teljesített vizsgák',
		logins: 'Belépések száma',
		suggestions: 'Javaslatok száma',
	},
	scoresTable: {
		university: 'Egyetem',
		students: 'Tanulók',
		student: 'Tanuló',
		score: 'Pontok',
		average: 'Átlag',
	},
	statistics: {
		student: {
			title: 'Hallgatói Ranglista',
		},
		university: {
			title: 'Egyetemi Ranglista',
		},
		office: {
			title: 'Egyetemi adatok - {{semester}}. félév',
			all: 'Összes egyetem',
		},
		progress: {
			title: 'Teszt kitöltések száma',
			text: 'modul teszt',
		},
		personality: {
			title: 'Személyiség tesztek eredményei',
		},
		registration: {
			title: 'Regisztrációs és projekt adatok',
			texts: {
				email: 'Aktuális félév hallgatóinak száma',
				project: 'Projektcsapatban dolgozó hallgatók száma',
				alone: 'Projekt csapatot kereső hallgatók száma',
			},
		},
		course: {
			title: 'Kurzus adatok',
			metrics: {
				finish: 'Teljes kurzust elvégzők száma',
				score: 'Legmagasabb elért pontszám',
				average: 'Egyetemi átlagpontszám',
				feedback: 'Beérkezett javaslatok száma',
			},
			suffix: {
				student: 'diák',
				score: 'pont',
				count: 'db',
			},
		},
	},
	certificate: {
		title: 'Gratulálunk a félév sikeres teljesítéséhez!',
		content: 'Töltsd le az okleveled és oszd meg másokkal is a sikeredet!',
		downloadBtnText: 'Oklevél letöltése',
	},
	fillBio: {
		title: 'Töltsd ki a profil adataidat!',
		subtitle:
			'Töltsd ki profil adataidat, hogy felkeltsd a projektgazdák figyelmét, és jobban megismerhessenek!',
		button: 'Profil adatok kitöltése',
	},
};
