export default {
	header: {
		title: 'Hallgatói lista',
		subtitle: 'Itt található a HSUP aktív hallgatóinak névsora.',
	},
	noResult: {
		text1: 'A megadott feltételekkel nem találtunk hallgatót.',
		text2: 'Kérjük bővítsd a szűrési feltételeket.',
	},
	tableHeaders: {
		name: 'Név',
		email: 'E-mail-cím',
		university: 'Egyetem',
		points: 'Pontszám',
		grade: 'Jegy',
	},
};
