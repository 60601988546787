const projectI18nHu = {
	findProject: {
		header: {
			title: 'Projekt kereső',
			subtitle: 'Böngéssz a projektek között és csatlakozz csapathoz!',
		},
		card: {
			remaining: 'Szabad helyek',
			count: 'db',
			full: 'Betelt!',
		},
		noResult: {
			text1: 'A megadott feltételekkel nem találtunk projektet.',
			text2: 'Kérjük bővítsd a szűrési feltételeket.',
		},
	},
	createProject: {
		title: 'Projektem',
		subtitle:
			'Ahhoz, hogy megtaláld csapatod tagjait, készítsd el projekted bemutatkozóját. Projektedet január 31-ig publikálhatod!',
		cardTitle: 'Készítsd el a projekted leírását',
		cardDescription:
			'Mielőtt megtalálod csapatod jövőbeli tagjait, publikálnod kell a projektedet, hogy mások számára is láthatóvá váljon. Ennek első lépéseként hozd létre a projektedet az alábbi gombra kattintva!',
		dialog: {
			title: 'Biztos publikálni szeretnéd projekted leírását?',
			subtitle:
				'Ha publikálod projekted leírását, az láthatóvá válik mindenki számára a platformon, így később már nem tudod szerkeszteni.',
			cancel: 'Mégsem',
			submit: 'Publikálás',
		},
	},
	projectApplications: {
		header: {
			title: 'Jelentkezéseim',
			subtitle:
				'A következő projektekre jelentkeztél. Az alábbi felkérések függő státuszban vannak.',
		},
		emptyState: {
			title: 'Még nem jelentkeztél projektre',
			subtitle:
				'Több projektre is jelentkezhetsz, de csak egy projektcsapathoz tudsz csatlakozni. Ha több projekt jelentkezésed is van függőben, akkor abba a csapatba fogsz bekerülni, amelyik csapat projektgazdája a jelentkezésedet először elfogadja. Nézz körül a projekt keresőben és válaszd ki a számodra legszimpatikusabb projektet. Siess, hiszen egy projekthez maximum 4 hallgató csatlakozhat.',
			primaryButton: 'Projekt kereső',
		},
		card: {
			projectOwner: 'projektfelelős',
			detailsButton: 'részletek',
			revokeButton: 'jelentkezés visszavonása',
		},
		revokeDialog: {
			title: 'Biztos vissza szeretnéd vonni a jelentkezést?',
			subtitle:
				'Ha visszavonod a jelentkezést, az eltűnik az ötletgaza számára a platformon, így később már nem tudja elfogadni.',
			cancel: 'Mégsem',
			submit: 'Visszavonás',
		},
	},
	manageProject: {
		acceptDialog: {
			title: 'Biztosan elfogadod a jelentkezőt?',
			subtitle:
				'Ha elfogadod a jelentkezőt, később már nem fogod tudni eltávolítani a csapatodból, a félév során vele kell dolgoznod.',
			cancel: 'Mégsem',
			submit: 'Elfogad',
		},
		rejectDialog: {
			title: 'Biztosan elutasítod a jelentkezőt?',
			subtitle:
				'Ha egyszer elutasítod a jelentkezőt, akkor többet nem fog tudni jelentkezni a projektedre.',
			cancel: 'Mégsem',
			submit: 'Elutasít',
		},
	},
	applyProject: {
		title: 'Jelentkezem a projektre',
		lead: 'Több projektre is jelentkezhetsz, de csak egy projektcsapathoz tudsz csatlakozni. Ha több projekt jelentkezésed is van függőben, akkor abba a csapatba fogsz bekerülni, amelyik csapat projektgazdája a jelentkezésedet először elfogadja.',
		motivation: {
			title: 'Mutatkozz be!*',
			label: 'Foglald össze miért szeretnél a projekten dolgozni és mivel tudnád segíteni a csapat munkáját.',
			placeholder: 'Max. 1.000 karakter',
		},
		cvFile: {
			title: 'Önéletrajz feltöltése (opcionális)',
			label: 'Önéletrajz',
			placeholder:
				'Itt tudod feltölteni az önéletrajzodat, ha szeretnéd megosztani a projektgazdával. (MAX. 5MB)',
		},
		submit: 'Jelentkezem a projektre',
		applySuccessMessage: 'Sikeres jelentkezés!',
		applyErrorMessage: 'Sikertelen jelentkezés!',
		revokeSuccessMessage: 'Sikeres visszavonás!',
		revokerrorMessage: 'Sikertelen visszavonás!',
	},
	team: {
		titleStart: 'Még',
		titleEnd: 'szabad hely van a csapatodban',
		titleFull: 'Nincs több szabad hely a projektben.',
		subtitle:
			'Egy csapat minimum 3, maximum 5 hallgatóból állhat. Tipp: bátran válassz más egyetem hallgatói közül is és próbálj meg minél több kompetenciát lefedni a csapatodban, hiszen így lehetsz a leghatékonyabb a projekt megvalósítása során.',
		header: {
			title: 'Csapattagok',
			subtitle:
				'Húzz bele, folyamatosan megy a verseny a legjobb emberekért. Találd meg a csapatod minél előbb!',
		},
		emptyState: {
			member: {
				title: 'Csapattagok',
				subtitle: 'Még nincsenek csapattagjaid',
				lead: 'A HSUP második féléve a csapatmunkáról és a nyertes projektötletek közös kidolgozásáról szól. Amennyiben még nem találtál csapattagokat, nézz körbe a Csapattag kereső menüpontban, csekkold a HSUP Virtuális Campus facebook csoportot és figyeld a Hírfolyamot, hogy részt vehess a csapatalakítást támogató rendezvényeken.',
				button: 'jelentkezések',
			},
			mentor: {
				title: 'Mentor',
				subtitle: 'Még nem jelentkeztél mentorhoz',
				lead: 'Nézz körül a mentorlistában, nehogy elhappolják előled a számodra legmegfelelőbb mentort. Fontos, hogy most csak 1 mentorod lehet, de több mentorhoz is adhatsz le jelentkezést. Az lesz a mentorod, aki a jelentkezésedet először elfogadja.',
				button: 'Mentor kereső',
			},
		},
	},
	mentorInterests: {
		header: {
			title: 'Érdeklődések',
			subtitle: {
				STUDENT:
					'Itt láthatóak azok a mentorok akiknek a projekted felkeltette az érdeklődésüket és szívesen mentorálnának a félév során.',
				MENTOR: 'Itt látható azoknak a projekteknek a listája amikre érdeklődést adtál le.',
			},
		},
		emptyState: {
			STUDENT: {
				title: 'Még nem érdeklődött mentor a projekted iránt',
				subtitle:
					'Amennyiben szimpatikusnak találja a projektedet egy mentor, érdeklődést adhat le rá és azok ezen az oldalon fognak megjelenni.',
			},
			MENTOR: {
				title: 'Még nem adtál le érdeklődést projektre',
				subtitle:
					'Amennyiben egy projekt felkelti az érdeklődésedet és szívesen mentorálnád a csapatot, leadahatsz a projektre érdeklődést. Keresd meg a számodra legszimpatikusabb projekteket a projektkeresőben!',
				primaryButton: 'Projekt kereső',
			},
		},
		interestSuccessMessage: 'Sikeres érdeklődés!',
		buttons: {
			disabled: 'Már érdeklődtél a projekt iránt',
			active: 'Érdekel a projekt',
		},
	},
	projectDetails: {
		motivation: 'Motivációs levél',
	},
};

export default projectI18nHu;
