import { Box, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';

const defaults = {
	autoClose: 7500,
	draggable: false,
	position: 'top-center',
	hideProgressBar: true,
};

const MessageWithRefreshButton = ({ content = '' }) => {
	return (
		<Box>
			<p>{content}</p>
			<p>{i18n.t('error.refresh')}</p>
		</Box>
	);
};

MessageWithRefreshButton.propTypes = {
	content: PropTypes.string.isRequired,
};

function refreshPage() {
	window.location.reload();
}

function redirect() {
	window.location.href = '/';
}

function refreshToast(content) {
	toast(<MessageWithRefreshButton content={content} />, {
		...defaults,
		type: 'error',
		closeOnClick: false,
		closeButton: false,
		onClick: refreshPage,
	});
}

function redirectToast(content) {
	toast(<MessageWithRefreshButton content={content} />, {
		...defaults,
		type: 'error',
		closeOnClick: false,
		closeButton: false,
		onClick: redirect,
	});
}

function genericRefreshToast() {
	toast(<MessageWithRefreshButton content={i18n.t('error.generic')} />, {
		...defaults,
		type: 'error',
		closeOnClick: false,
		closeButton: false,
		onClick: refreshPage,
	});
}

function errorToast(content) {
	toast(content, {
		...defaults,
		type: 'error',
	});
}

function successToast(content) {
	toast(content, {
		...defaults,
		type: 'success',
	});
}

const InfoBox = ({ content }) => (
	<Box display="flex">
		<Box mr={2}>
			<Info />
		</Box>
		<Typography variant="h4" style={{ color: '#FFFFFF' }}>
			{content}
		</Typography>
	</Box>
);

function notificationToast(content, onClick = () => {}) {
	toast.info(<InfoBox content={content} />, {
		position: 'top-right',
		autoClose: 7500,
		hideProgressBar: false,
		closeOnClick: true,
		draggable: true,
		onClick,
	});
}

export {
	errorToast,
	successToast,
	refreshToast,
	genericRefreshToast,
	redirectToast,
	notificationToast,
};
