import {
	BookOutlined,
	DashboardOutlined,
	DescriptionOutlined,
	FolderOpenOutlined,
	FormatListBulleted,
	ImageSearch,
	InfoOutlined,
	NotificationsOutlined,
	People,
	PeopleOutlined,
	RecordVoiceOverOutlined,
	SearchOutlined,
	SettingsOutlined,
	ShowChart,
	StarBorderOutlined,
} from '@material-ui/icons';
import { URL_PREFIXES } from 'app/constants';

const { STUDENT, MENTOR, OFFICE, UNIVERSITY } = URL_PREFIXES;

export const navbarItems = {
	firstSemester: {
		student: [
			{
				titleKey: 'dashboard',
				href: `${STUDENT}/`,
				icon: DashboardOutlined,
			},
			{
				titleKey: 'modules',
				href: `${STUDENT}/modules`,
				icon: BookOutlined,
			},
			{
				titleKey: 'studentOnePager',
				href: `${STUDENT}/one-pager`,
				icon: DescriptionOutlined,
			},
			{
				titleKey: 'news',
				href: `${STUDENT}/news`,
				icon: FormatListBulleted,
			},
			{
				titleKey: 'startupLabEvents',
				href: `${STUDENT}/startup-lab-events`,
				icon: StarBorderOutlined,
			},
			{
				titleKey: 'settings',
				href: `${STUDENT}/settings`,
				icon: SettingsOutlined,
			},
			{
				titleKey: 'help',
				href: `${STUDENT}/help`,
				icon: InfoOutlined,
			},
		],
		mentor: [
			{
				titleKey: 'modules',
				href: `${MENTOR}/modules`,
				icon: BookOutlined,
			},
			{
				titleKey: 'news',
				href: `${MENTOR}/news`,
				icon: FormatListBulleted,
			},
			{
				titleKey: 'startupLabEvents',
				href: `${MENTOR}/startup-lab-events`,
				icon: StarBorderOutlined,
			},
			{
				titleKey: 'mentor.menuItems.findMentors',
				href: `${MENTOR}/mentors/find-mentors`,
				icon: RecordVoiceOverOutlined,
			},
			{
				titleKey: 'settings',
				href: `${MENTOR}/settings`,
				icon: SettingsOutlined,
			},
			{
				titleKey: 'help',
				href: `${MENTOR}/help`,
				icon: InfoOutlined,
			},
		],
		office: [
			{
				titleKey: 'dashboard',
				href: `${OFFICE}/`,
				icon: DashboardOutlined,
			},
			{
				titleKey: 'onepager.title',
				icon: DescriptionOutlined,
				menuItems: [
					{
						titleKey: 'onepager.menuItems.submittedOnepagers',
						href: `${OFFICE}/one-pager`,
					},
					{
						titleKey: 'onepager.menuItems.corporateProblems',
						href: `${OFFICE}/corporate-problems`,
					},
				],
			},
			{
				titleKey: 'pushNotifications',
				href: `${OFFICE}/push-notification`,
				icon: NotificationsOutlined,
			},
			{
				titleKey: 'modules',
				href: `${OFFICE}/modules`,
				icon: BookOutlined,
			},
			{
				titleKey: 'students',
				href: `${OFFICE}/students`,
				icon: PeopleOutlined,
			},
			{
				titleKey: 'news',
				href: `${OFFICE}/news`,
				icon: FormatListBulleted,
			},
			{
				titleKey: 'startupLabEvents',
				href: `${OFFICE}/startup-lab-events`,
				icon: StarBorderOutlined,
			},
			{
				titleKey: 'mentor.menuItems.findMentors',
				icon: PeopleOutlined,
				href: `${OFFICE}/mentors/find-mentors`,
			},
			{
				titleKey: 'settings',
				href: `${OFFICE}/settings`,
				icon: SettingsOutlined,
			},
			{
				titleKey: 'help',
				href: `${OFFICE}/help`,
				icon: InfoOutlined,
			},
		],
		university: [
			{
				titleKey: 'dashboard',
				href: `${UNIVERSITY}/`,
				icon: DashboardOutlined,
			},
			{
				titleKey: 'onepager.title',
				href: `${UNIVERSITY}/one-pager`,
				icon: DescriptionOutlined,
			},
			{
				titleKey: 'modules',
				href: `${UNIVERSITY}/modules`,
				icon: BookOutlined,
			},
			{
				titleKey: 'students',
				href: `${UNIVERSITY}/students`,
				icon: PeopleOutlined,
			},
			{
				titleKey: 'news',
				href: `${UNIVERSITY}/news`,
				icon: FormatListBulleted,
			},
			{
				titleKey: 'startupLabEvents',
				href: `${UNIVERSITY}/startup-lab-events`,
				icon: StarBorderOutlined,
			},
			{
				titleKey: 'settings',
				href: `${UNIVERSITY}/settings`,
				icon: SettingsOutlined,
			},
			{
				titleKey: 'help',
				href: `${UNIVERSITY}/help`,
				icon: InfoOutlined,
			},
		],
	},
	secondSemester: {
		student: {
			projectOwner: [
				{
					titleKey: 'dashboard',
					href: `${STUDENT}/`,
					icon: DashboardOutlined,
				},
				{
					titleKey: 'modules',
					href: `${STUDENT}/modules`,
					icon: BookOutlined,
				},
				{
					titleKey: 'myProject',
					icon: FolderOpenOutlined,
					href: `${STUDENT}/my-project/`,
				},
				{
					titleKey: 'project.menuItems.findProject',
					icon: SearchOutlined,
					href: `${STUDENT}/project/find-projects`,
				},

				{
					titleKey: 'myTeam.title',
					icon: People,
					menuItems: [
						{
							titleKey: 'myTeam.menuItems.team',
							href: `${STUDENT}/my-project/team`,
						},
						{
							titleKey: 'myTeam.menuItems.applications',
							href: `${STUDENT}/my-project/manage-applications`,
						},
						{
							titleKey: 'memberFinder.list',
							href: `${STUDENT}/my-project/team/member-finder/list`,
						},
						{
							titleKey: 'memberFinder.invitations',
							href: `${STUDENT}/my-project/team/member-finder/invitations`,
						},
					],
				},
				{
					titleKey: 'mentor.title',
					icon: PeopleOutlined,
					menuItems: [
						{
							titleKey: 'mentor.menuItems.findMentors',
							href: `${STUDENT}/mentors/find-mentors`,
						},
						{
							titleKey: 'mentor.menuItems.applications',
							href: `${STUDENT}/mentors/mentor-applications`,
						},
						{
							titleKey: 'mentor.menuItems.mentorInterests',
							href: `${STUDENT}/mentors/mentor-interests`,
						},
					],
				},
				{
					titleKey: 'news',
					href: `${STUDENT}/news`,
					icon: FormatListBulleted,
				},
				{
					titleKey: 'startupLabEvents',
					href: `${STUDENT}/startup-lab-events`,
					icon: StarBorderOutlined,
				},
				{
					titleKey: 'settings',
					href: `${STUDENT}/settings`,
					icon: SettingsOutlined,
				},
				{
					titleKey: 'help',
					href: `${STUDENT}/help`,
					icon: InfoOutlined,
				},
			],
			projectMember: [
				{
					titleKey: 'dashboard',
					href: `${STUDENT}/`,
					icon: DashboardOutlined,
				},
				{
					titleKey: 'modules',
					href: `${STUDENT}/modules`,
					icon: BookOutlined,
				},
				{
					titleKey: 'project.title',
					icon: FolderOpenOutlined,
					menuItems: [
						{
							titleKey: 'project.menuItems.findProject',
							href: `${STUDENT}/project/find-projects`,
						},
						{
							titleKey: 'project.menuItems.applications',
							href: `${STUDENT}/project/project-applications`,
						},
						{
							titleKey: 'project.menuItems.myProject',
							href: `${STUDENT}/my-project/`,
						},
						{
							titleKey: 'project.menuItems.invitations',
							href: `${STUDENT}/project/invitations`,
						},
					],
				},
				{
					titleKey: 'news',
					href: `${STUDENT}/news`,
					icon: FormatListBulleted,
				},
				{
					titleKey: 'startupLabEvents',
					href: `${STUDENT}/startup-lab-events`,
					icon: StarBorderOutlined,
				},
				{
					titleKey: 'mentor.menuItems.findMentors',
					icon: PeopleOutlined,
					href: `${STUDENT}/mentors/find-mentors`,
				},
				{
					titleKey: 'settings',
					href: `${STUDENT}/settings`,
					icon: SettingsOutlined,
				},
				{
					titleKey: 'help',
					href: `${STUDENT}/help`,
					icon: InfoOutlined,
				},
			],
		},
		mentor: [
			{
				titleKey: 'modules',
				href: `${MENTOR}/modules`,
				icon: BookOutlined,
			},
			{
				titleKey: 'project.title',
				icon: FolderOpenOutlined,
				menuItems: [
					{
						titleKey: 'project.menuItems.findProject',
						href: `${MENTOR}/project/find-projects`,
					},
					{
						titleKey: 'project.menuItems.applications',
						href: `${MENTOR}/project/project-applications`,
					},
					{
						titleKey: 'project.menuItems.mentoredProjects',
						href: `${MENTOR}/mentored-projects/`,
					},
					{
						titleKey: 'project.menuItems.mentorInterests',
						href: `${MENTOR}/project/mentor-interests`,
					},
				],
			},
			{
				titleKey: 'news',
				href: `${MENTOR}/news`,
				icon: FormatListBulleted,
			},
			{
				titleKey: 'startupLabEvents',
				href: `${MENTOR}/startup-lab-events`,
				icon: StarBorderOutlined,
			},
			{
				titleKey: 'mentor.menuItems.findMentors',
				href: `${MENTOR}/mentors/find-mentors`,
				icon: PeopleOutlined,
			},
			{
				titleKey: 'settings',
				href: `${MENTOR}/settings`,
				icon: SettingsOutlined,
			},
			{
				titleKey: 'help',
				href: `${MENTOR}/help`,
				icon: InfoOutlined,
			},
		],
		office: [
			{
				titleKey: 'dashboard',
				href: `${OFFICE}/`,
				icon: DashboardOutlined,
			},
			{
				titleKey: 'progressReport',
				href: `${OFFICE}/progress-report`,
				icon: ShowChart,
			},
			{
				titleKey: 'pushNotifications',
				href: `${OFFICE}/push-notification`,
				icon: NotificationsOutlined,
			},
			{
				titleKey: 'modules',
				href: `${OFFICE}/modules`,
				icon: BookOutlined,
			},
			{
				titleKey: 'project.menuItems.findProject',
				href: `${OFFICE}/project/find-projects`,
				icon: ImageSearch,
			},
			{
				titleKey: 'students',
				href: `${OFFICE}/students`,
				icon: PeopleOutlined,
			},
			{
				titleKey: 'news',
				href: `${OFFICE}/news`,
				icon: FormatListBulleted,
			},
			{
				titleKey: 'startupLabEvents',
				href: `${OFFICE}/startup-lab-events`,
				icon: StarBorderOutlined,
			},
			{
				titleKey: 'mentor.menuItems.findMentors',
				href: `${OFFICE}/mentors/find-mentors`,
				icon: RecordVoiceOverOutlined,
			},
			{
				titleKey: 'settings',
				href: `${OFFICE}/settings`,
				icon: SettingsOutlined,
			},
			{
				titleKey: 'help',
				href: `${OFFICE}/help`,
				icon: InfoOutlined,
			},
		],
		university: [
			{
				titleKey: 'dashboard',
				href: `${UNIVERSITY}/`,
				icon: DashboardOutlined,
			},
			{
				titleKey: 'progressReport',
				href: `${UNIVERSITY}/progress-report`,
				icon: ShowChart,
			},
			{
				titleKey: 'modules',
				href: `${UNIVERSITY}/modules`,
				icon: BookOutlined,
			},
			{
				titleKey: 'project.menuItems.findProject',
				href: `${UNIVERSITY}/project/find-projects`,
				icon: ImageSearch,
			},
			{
				titleKey: 'students',
				href: `${UNIVERSITY}/students`,
				icon: PeopleOutlined,
			},
			{
				titleKey: 'news',
				href: `${UNIVERSITY}/news`,
				icon: FormatListBulleted,
			},
			{
				titleKey: 'startupLabEvents',
				href: `${UNIVERSITY}/startup-lab-events`,
				icon: StarBorderOutlined,
			},
			{
				titleKey: 'mentor.menuItems.findMentors',
				href: `${UNIVERSITY}/mentors/find-mentors`,
				icon: SearchOutlined,
			},
			{
				titleKey: 'settings',
				href: `${UNIVERSITY}/settings`,
				icon: SettingsOutlined,
			},
			{
				titleKey: 'help',
				href: `${UNIVERSITY}/help`,
				icon: InfoOutlined,
			},
		],
	},
};
