export default {
	pageTitle: 'Mobil értesítések',
	title: 'Mobil értesítések',
	subtitle:
		'A legfontosabb tudnivalók kiküldése push értesítésként. Azok a hallgatók kapják meg, akik letöltötték az applikációt és engedélyezték az értesítéseket.',
	new: {
		title: 'Új értesítés küldése',
		subtitle:
			'Az értesítést azok a hallgatók kapják meg, akik letöltötték az applikációt és engedélyezték az értesítéseket.',
		notificationTitle: 'értesítés címe (max 50 karakter)',
		notificationTitlePlaceholder: 'Kedves diákok...',
		notificationContent: 'értesítés szövege (max 300 karakter)',
		notificationContentPlaceholder: 'Kedves diákok...',
		notificationRedirect: 'aloldal, amire az alkalmazásban viszi az értesítés a felhasználót',
		notificationRedirectPlaceholder: 'Válassz aloldalt!',
		sendNotification: 'Értesítés küldése',
		dialog: {
			title: 'Biztos ki szeretnéd küldeni az értesítést az összes diák számára?',
			content:
				'Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Curabitur blandit tempus porttitor.',
		},
	},
	older: {
		title: 'Korábbi értesítések',
		redirectLabel: 'Aloldal',
		sentTo: 'Kiküldve',
		toUser: '{{ number }} felhasználónak',
	},
	redirectOptions: {
		dashboard: 'Irányítópult',
		modules: 'Modulok',
		news: 'Hírfolyam',
		startupLab: 'Események',
	},
};
