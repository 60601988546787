export function mapMentorToListItem(mentor) {
	return {
		id: mentor._id,
		fullName: mentor.fullName,
		profilePictureUrl: mentor.profilePictureUrl,
		specializations: mentor.specializations,
		focuses: mentor.focuses,
		headline: mentor.headline,
		introduction: mentor.introduction,
		social: mentor.social,
		remainingSeats: mentor.remainingSeats,
	};
}

export function calculateHasAcceptedMentors(applications) {
	return applications?.some((application) => application.status === 'ACCEPTED');
}
