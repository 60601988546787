import { URL_PREFIXES } from 'app/constants';
import { OfficeLayout } from 'app/layouts/User';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const { OFFICE } = URL_PREFIXES;

const officeRoutes = {
	path: OFFICE,
	component: OfficeLayout,
	routes: [
		{
			path: `${OFFICE}`,
			exact: true,
			component: lazy(() => import('features/dashboard/office')),
		},
		{
			path: `${OFFICE}/corporate-problems`,
			exact: true,
			component: lazy(() => import('features/corporate-problems')),
		},
		{
			path: `${OFFICE}/news`,
			exact: true,
			component: lazy(() => import('features/news')),
		},
		{
			path: `${OFFICE}/one-pager`,
			exact: true,
			component: lazy(() => import('features/one-pager/admin/OnePager')),
		},
		{
			path: `${OFFICE}/students`,
			exact: true,
			component: lazy(() => import('features/student')),
		},
		{
			path: `${OFFICE}/push-notification`,
			exact: true,
			component: lazy(() => import('features/push-notification')),
		},
		{
			path: `${OFFICE}/help`,
			exact: true,
			component: lazy(() => import('features/help')),
		},
		{
			path: `${OFFICE}/project/project-detail/:projectId`,
			exact: true,
			component: lazy(() => import('features/project/project-detail')),
		},
		{
			path: `${OFFICE}/project/find-projects`,
			exact: true,
			component: lazy(() => import('features/project/find-projects')),
		},
		{
			path: `${OFFICE}/modules`,
			exact: true,
			component: lazy(() => import('features/progress/progress')),
		},
		{
			path: `${OFFICE}/lessons/:id`,
			exact: true,
			component: lazy(() => import('features/lesson/lesson')),
		},
		{
			path: `${OFFICE}/mentors/find-mentors`,
			exact: true,
			component: lazy(() => import('features/mentor/find-mentors/find-mentors')),
		},
		{
			path: `${OFFICE}/progress-report`,
			exact: true,
			component: lazy(() => import('features/progress-report-list/progress-report-office')),
		},
		{
			path: `${OFFICE}/startup-lab-events`,
			exact: true,
			component: lazy(() => import('features/startup-lab-event')),
		},
		{
			path: `${OFFICE}/startup-lab-events/:startupLabEventId`,
			exact: true,
			component: lazy(() => import('features/startup-lab-event-details')),
		},
		{
			path: `${OFFICE}/settings`,
			exact: true,
			component: lazy(() => import('features/settings')),
		},
		{
			component: () => <Redirect to="/errors/error-404" />,
		},
	],
};

export default officeRoutes;
