export default {
	buttons: {
		prev: 'Vissza',
		next: 'Tovább',
	},
	title: 'Onboarding',
	subtitle:
		'Az irányítópult megtekintése előtt olvasd el a félévhez tartozó legfontosabb információkat!',
	onboarded: {
		firstSemester: {
			title: 'Onboard',
			submit: 'Személyiségteszt',
		},
		secondSemester: {
			title: 'Onboard',
			submit: 'Befejezés',
			owner: 'Ötletgazdáknak',
			member: 'HSUP hallgatóknak',
		},
	},
};
