export default {
	header: {
		title: 'Progress report',
		subtitle:
			'Itt találod a félév során feltöltött progress report-okat. A státusz folyamatosan frissül, ahogy a hallgatók töltik fel a csapatuk progress report-ját. A report-ot az ötletgazda tölti fel a saját tárgyfelelősének.',
	},
	subheading: {
		start: 'Eddig',
		end: 'csapat töltött fel a progress reportot.',
	},
	content: {
		projectName: 'Projekt neve',
		projectOwner: 'Projekt felelős',
		uploadDate: 'Feltöltés időpontja',
	},
};
