export default {
	onboardButtons: {
		prev: 'Vissza',
		next: 'Tovább',
	},
	onboardingTitle: 'Onboarding',
	onboardingSubTitle:
		'Az irányítópult megtekintése előtt olvasd el a félévhez tartozó legfontosabb információkat!',
	onboarded: {
		firstSemester: {
			title: 'Onboard',
			submit: 'Személyiségteszt',
		},
		secondSemester: {
			title: 'Onboard',
			submit: 'Befejezés',
			owner: 'Ötletgazdáknak',
			member: 'HSUP hallgatóknak',
		},
	},
	welcome: {
		header: {
			title: 'Üdv,',
			subtitle: 'Mielőtt belekezdenél a tanulásba, végezd el a személyiség tesztet!',
		},
		subheader: {
			title: 'Milyen személyiség vagy?',
			subtitle: 'Derítsük ki közösen!',
			button: 'Teszt Inditás',
			text: 'Az, hogy egy startupon belül milyen szerepet töltesz be, rengeteg tényezőtől függ. A HSUP-ban négy alapítói profilt különítettünk el, amelyhez személyre szabott bónusz tananyagok is tartoznak. A profilokról a lenti kártyákra kattintva olvashatsz bővebben, ha készen állsz, kattints a "Teszt indítás" gombra!',
		},
	},
	detail: {
		headline: {
			title: 'Milyen személyiségjegyekkel rendelkezik egy',
			subtitle: 'Lássuk!',
		},
		summary: {
			ceo: {
				title: '-k, mint felfedezők',
			},
			cto: {
				title: '-k, mint analitikusok',
			},
			pm: {
				title: '-ek, mint védelmezők',
			},
			kreativ: {
				title: '-ok, mint diplomaták',
			},
			testButton: 'Teszt Indítása',
			backButton: 'Vissza',
		},
	},
	test: {
		header: {
			title: 'Kiderítjük, mi passzol hozzád',
			subtitle: 'Személyiség teszt',
		},
		subheader: {
			title: 'Milyen személyiség vagy?',
			subtitle: 'Válaszolj őszintén a pontos eredményért',
		},
		form: {
			buttons: {
				next: 'Következő',
				prev: 'Vissza',
				submit: 'Teszt beküldése',
			},
			text: {
				agree: 'Egyetértek',
				disagree: 'Nem értek egyet',
			},
		},
	},
	success: {
		headline: {
			titleStart: 'Rád legjobban a',
			titleEnd: 'személyisége illik.',
			titleMulti:
				'Hoppá, az algoritmusunk alapján úgy látszik nem csak egy személyiséggel vannak közös vonásaid. Válaszd ki a számodra legjobban megfelelőt.',
			subtitle:
				'A tantárgy során ennek a személyiségnek megfelelő bónusz tananyagokat fogsz kapni.',
		},
		summary: {
			title: '-król röviden',
			submit: 'Folytatás mint',
			ceo: {
				title: '-król röviden',
				text: 'Energikus, inspiráló vezető vagy. Lendületesen haladsz előre és töröd az utat a csapatnak. Befektetőkkel tárgyalsz, networking eseményeken gyűjtöd a névjegyeket és fáradhatatlanul dolgozol a vízió megvalósításán. A szabályok vonalvezetőnek megteszik, de te inkább az új irányokon szeretsz gondolkozni, amelyektől a startupod a “következő nagy dolog” lehet.',
				long: 'Az ügyvezető. Előretekintő, kreatív és jó vezetővé kell válnod ahhoz, hogy csapatodból és ötletetekből a legtöbbet hozhassátok ki. Inspiráló egyén vagy, nem futamodsz meg az újdonságoktól és a kihívásokat keresed. A csapat céljait egymás között is pontosan meg kell határoznotok, de azokat a külvilág felé Neked kell majd kommunikálnod. Fontos számodra, hogy a kezedben legyen az irányítás, és ha ez megvan,rengeteg lelkesedéssel tudsz egy projekten dolgozni. Amire azonban figyelned kell, hogy fókuszáltan dolgozz, és ne kalandozz el olyan területekre, ami a csapatodat hátráltatná. A networking események szíve és lelke vagy. Szeretsz másokkal beszélni és mások is szeretnek hallgatni téged, hiszen elszántsággal, motiváltan beszélsz az ötletről. Vigyáznod kell azonban, mert a nagyfokú szabadságot a a siker érdekében gyakran le kell cserélned szigorúbb munkarendszerekre, ha gyorsan és hatékonyan szeretnétek előrehaladni. Habár lelkesedésből nincs benned hiány, jobb, ha már az elején tisztában vagy vele, hogy egy startupot építeni nem kis falat és sok olyan megpróbáltatással kell majd szembenézned az elkövetkezendő időkben, amelyekre a csapatoddal együtt, és magadban is fel kell majd készülnöd. A szabályokat lazábban értelmező csapattagként kétélű fegyverrel bírsz. Nagyon sok kreatív, új módszer jut majd eszedbe, a termék, marketing, branding, vagy akár az értékesítés terén is, azonban figyelned kell, hogy a munkád során akkor se veszítsd el a lelkesedést, amikor monotonabb időszakok érkeznek.',
			},
			cto: {
				title: '-król röviden',
				text: 'Pragmatikus, jól átgondolt és magasan funkcionáló rendszerekben gondolkozol. Ez a ritka képesség remekül fog jönni, amikor a technológiai oldal stratégiai döntéseit kell meghoznod. Jövőbe tekintő csapattagként mindig egy lépéssel előrébb jársz fejben, mint a termék. Ha kérdés merül fel, a többiek bátran fordulnak hozzád, mert higgadt és elegáns megoldásaid mindig célba érnek.',
				long: 'Technológiai vezetőként, te a tökéletességre törekszel. Nem elégszel meg a hanyag munkával, sőt alapos átgondolással és a különböző tényezők mérlegelésével mindig ez ellen dolgozol. . Részedről addig nem mehettek előre, amíg a különböző álláspontok nincsenek megvizsgálva, hiszen lehet, hogy valakinél ott lapul a “legjobb” megoldás. A rengeteg kérdés ellenére is magabiztosan lavírozol a technológia és a csapat között, mindig a kezedben tartod a dolgokat. Kemény, de igazságos vezető vagy, aki a dicséretet és a kritikát is a fejlődés jegyében adja a többieknek. Vigyáznod kell azonban, mert sokan sok félék vagytok a csapatban és nem mindenki tudja tartani azt a fajta rendet, amiben te megálmodtad a fejlesztés folyamatát. Türelmet kell gyakorolnod, és higgadt érveléssel  kell meggyőznöd másokat, amikor úgy érzed, neked van igazad. Az érzelmi világot ugyanúgy figyelembe kell venned, akár csak a technológiai részleteket. Figyelned kell, hogy amikor úgy érzed túl sok időt pazaroltok egy kérdésre, amire te már tudod a választ, ne legyél türelmetlen és ítélkező azokkal szemben, akik még ötletelnek. Gyors, magabiztos és leggyakrabban pontos, ösztönös döntéseid rengeteg előnyt biztosítanak majd a startupodnak, amit ha kemény munkával, nyitottsággal, kompromisszumkészséggel egészítesz ki, minden lehetőséged meg lesz, hogy csapatoddal sikerre vigyétek az ötleteteket.',
			},
			pm: {
				title: '-ekről röviden',
				text: 'Egy startup működhet gyorsan, de egy igazán jó projekt menedzser nélkül sosem lesz az igazi a tempó. Elképesztő szervezési készségeid olyan értéket jelentenek a startupnak, amely nélkül lomha és nehézkes lenne az előrehaladás. A folyamatok megszervezése és az azokhoz való igazodás a te igazi mesterséged.',
				long: 'Rend a lelke mindennek. A technológiai vezetővel együtt te vagy az a bizonyos ember, aki a rendszerért felelős. Esetedben nem feltétlenül a technológiában, hanem a munkában. Te vagy a startup ragasztója, azoknak a láthatatlan folyamatoknak az összekapcsolója, amelyet csak remek kommunikációval és nagyfokú szervezettséggel lehet megvalósítani. Átgondolt, higgadt és pragmatikus hozzáállásod rengeteg fejfájást spórolhat meg a csapatnak. Egy kis transzparencia növelés itt, egy kis folyamat optimalizálás ott és a csapatod máris hatékonyan kommunikál, és azt látod, hogy a feladatok csak úgy pörögnek az emberek kezei alatt. A konfliktusok azonban téged sem kerülhetnek el, hiszen a folyamatokat “könyv szerint” csinálni sokszor a való életben nem működik, és ezt leginkább az ügyvezető tudhatja. Mérlegeld nyugodtan a helyzeteket és dönts úgy, hogy az a startupodnak a legkedvezőbb legyen. Egy másik fenyegető veszély nem odakint vár rád, hanem odabent. Lelkiismeretes munkaerőként nagyon nehezen mondasz nemet kérésekre, egyéb munkákra, és habár egészen más okok miatt, mint az ügyvezető, ez gyakran fókuszvesztéshez vezet. Ettől leterheltnek érzed magad és szétszakad az addigi remekül felépített rendszered. Hidegvérű startupperként azonban ha átgondolod, hogy a folyamatok merre vezetnek és mások véleményét is kikéred, akkor habár nehezedre fog esni, de képes leszel csakis egy célra fókuszálni, mégpedig a startupod sikerére.',
			},
			kreativ: {
				title: '-okról röviden',
				text: 'Merész, új és kreatív ötleteid adják a termék szívét-lelkét. Kreativitásod határtalan és azt a startupod megoldásainak szépsége, felhasználhatósága és rengeteg újdonsága mutatja. Eredeti meglátásaid és játékos hozzáállásod másoknak is inspirációt jelent, és ezzel is segíted a csapatod jó morálját és biztos előrehaladását.',
				long: 'A művészet a művészetekért. Legyen az papír, vászon vagy kotta, a te világod a kreativitásé és az önkifejezésé. Függően attól, hogy melyik oldalad a legerősebb, lehetsz te a csapat legjobb szövegírója, designere, vagy a tökéletes felhasználói élmény megtervezője is. Eredeti ötleteiddel hihetetlen mennyiségű színt vihetsz az eddig még monokróm felületekre, és ezzel teheted igazán egyedivé a terméket. Kreatív, optimista csapattagként új, akár néha egészen apró újításokkal a többiek kedvét is jobbá tudod varázsolni, hiszen mindig kell valaki, aki a problémákat új szempontból vizsgálja. A rutin nem a te világod, ez akár nehézségeket is okozhat később. Neked is meg kell tanulnod, hogy a művészi önkifejezés nem lehet céltalan, és hogy a startup sikere érdekében új módokon kell becsatornáznod kreatív ötleteidet. A megpróbáltatások téged sem kerülnek el, erre jobb, ha már korán felkészülsz. Készen kell állnod nem csak üzleti döntések meghozatalára, de azokra a kompromisszumokra is, amelyek ugyan a művészetet csorbíthatják, de az üzletet előrelendítik. Visszahúzódóbb természetként a startup világ sodrása egy ideig elkerülhet, de ez lehetőséget is adhat számodra, hogy megfigyelőként olyan részletekre bukkanj, amelyek felett a többiek elsiklottak. Tanácsaid és ötleteid a startup terméket élettel, játékossággal teszik teljessé, egy olyan termékké, amely nélküled pont ugyanolyan lenne “mint a többi”.',
			},
		},
		notSure: {
			title: 'Nem érzed megfelelőnek az eredményt?',
			subtitle: 'Válaszd ki te magad, melyik személyiséget érzed magadénak',
		},
	},
};
