import { colors } from '@material-ui/core';

const white = '#FFFFFF';

export default {
	primary: {
		contrastText: white,
		main: '#80BC00',
	},
	secondary: {
		contrastText: white,
		dark: colors.blue[900],
		main: '#0e1655',
		light: colors.blue.A400,
	},
	error: {
		contrastText: white,
		dark: colors.red[900],
		main: colors.red[600],
		light: colors.red[400],
	},
	text: {
		primary: '#607D8B',
		secondary: '#37474F',
		link: '#80BC00',
	},
	background: {
		default: '#F5F6F9',
		paper: white,
		paperDark: '#ECEFF1',
		gradient: 'linear-gradient(180deg, #F5F6F9 0%, rgba(245, 246, 249, 0.0001) 38.54%)',
	},
	divider: '#F5F6F9',
};
