import { colors } from '@material-ui/core';
import palette from './palette';

export default {
	fontFamily: 'Poppins',
	h1: {
		color: palette.secondary.main,
		fontWeight: 700,
		fontSize: '30px',
		lineHeight: '45px',
	},
	h2: {
		color: palette.secondary.main,
		fontWeight: 700,
		fontSize: '24px',
		lineHeight: '36px',
	},
	h3: {
		color: palette.secondary.main,
		fontWeight: 600,
		fontSize: '22px',
		lineHeight: '33px',
	},
	h4: {
		color: palette.secondary.main,
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '27px',
	},
	h5: {
		color: palette.secondary.main,
		fontWeight: 600,
		fontSize: '16px',
		lineHeight: '24px',
	},
	body1: {
		color: colors.blueGrey[900],
		fontFamily: 'Nunito Sans',
		fontSize: '16px',
		lineHeight: '25.6px',
	},
	caption: {
		color: colors.blueGrey[500],
		fontWeight: 500,
		fontSize: '12px',
		lineHeight: '18px',
		textTransform: 'uppercase',
		display: 'block',
	},
	overline: {
		color: colors.blueGrey[300],
		fontWeight: 500,
		fontSize: '12px',
		lineHeight: '18px',
	},
};
