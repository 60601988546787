const startupLabEvents = {
	header: {
		title: 'Események',
		subtitle:
			'Összegyűjtöttük neked a legizgalmasabb hazai kihívásokat, versenyeket és lehetőségeket. Kattints a részletekért és tudj meg többet!',
	},
	noResult: {
		text1: 'A megadott feltételekkel nem találtunk eseményt.',
		text2: 'Kérjük bővítsd a szűrési feltételeket.',
	},
	upload: {
		header: 'Új esemény feltöltése',
		subtitle: 'Esemény feltöltéséhez kattintson az “új esemény feltöltése” gombra!',
		submitBtn: 'Új esemény feltöltése',
		toastSuccessMsg: 'Sikeres feltöltés',
	},
	edit: {
		header: 'Esemény szerkesztése',
		submitBtn: 'Módosítás publikálása',
		toastSuccessMsg: 'Sikeres módosítás',
	},
	form: {
		header: 'Új esemény feltöltése!',
		titleFieldTitle: 'Esemény címe',
		subtitleFieldTitle: 'Esemény alcíme',
		descriptionFieldTitle: 'Esemény szövege',
		categoryFieldTitle: 'Kategória kiválasztása',
		emailFieldTitle: 'E-mail cím kapcsolatfelvételre',
		linkFieldTitle: 'Weboldal kapcsolatfelvételre',
		startDateFieldTitle: 'Esemény kezdete',
		endDateFieldTitle: 'Esemény vége',
		logoFieldTitle: 'Esemény/Partner logója',
		logoFieldSubTitle: 'Kattints ide vagy húzd ide a képet a módosításhoz!',
		pictureFormatText: 'Feltölthető formátumok:',
		cancelLabel: 'Mégsem',
		okLabel: 'Kiválasztás',
		backBtn: 'Mégsem',
		validationMaxLength: 'Max {{count}} karaktert adhatsz meg!',
		validateCategoriesMinCount: 'Legalább egyet adj meg!',
		validateCategoriesMaxCount: 'Legfeljebb ötöt adj meg!',
		validationEmailRequired: 'Email megadása kötelező!',
		validationStartDateRequired: 'Kezdő dátum megadása kötelező!',
		validationEndDateRequired: 'Végdátum megadása kötelező!',
		validateEndDate: 'A végdátum nem lehet korábbi mint a kezdő dátum!',
		validationBadFormat: 'Hibás formátum',
	},
	delete: {
		title: 'Biztosan törölni szeretné?',
		confirmBtn: 'Törlés',
		backBtn: 'Mégsem',
		toastSuccessMsg: 'Sikeres törlés',
	},
	list: {
		remainingText: 'nap maradt hátra',
		expiredText: 'Ez az esemény lejárt',
		detailsText: 'tovább a eseményre',
	},
	detail: {
		contact: 'Elérhetőség',
		description: 'Esemény leírása',
		subtitle: 'Esemény alcíme',
		link: 'esemény részletei',
		linkEmail: 'Kapcsolatfelvétel',
		deadline: 'Határidő',
	},
};

export default startupLabEvents;
