import { URL_PREFIXES } from 'app/constants';
import { UniversityLayout } from 'app/layouts/User';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const { UNIVERSITY } = URL_PREFIXES;

const universityRoutes = {
	path: UNIVERSITY,
	component: UniversityLayout,
	routes: [
		{
			path: `${UNIVERSITY}`,
			exact: true,
			component: lazy(() => import('features/dashboard/university')),
		},
		{
			path: `${UNIVERSITY}/one-pager`,
			exact: true,
			component: lazy(() => import('features/one-pager/admin')),
		},
		{
			path: `${UNIVERSITY}/news`,
			exact: true,
			component: lazy(() => import('features/news')),
		},
		{
			path: `${UNIVERSITY}/students`,
			exact: true,
			component: lazy(() => import('features/student')),
		},
		{
			path: `${UNIVERSITY}/help`,
			exact: true,
			component: lazy(() => import('features/help')),
		},
		{
			path: `${UNIVERSITY}/project/project-detail/:projectId`,
			exact: true,
			component: lazy(() => import('features/project/project-detail')),
		},
		{
			path: `${UNIVERSITY}/project/find-projects`,
			exact: true,
			component: lazy(() => import('features/project/find-projects')),
		},
		{
			path: `${UNIVERSITY}/modules`,
			exact: true,
			component: lazy(() => import('features/progress/progress')),
		},
		{
			path: `${UNIVERSITY}/lessons/:id`,
			exact: true,
			component: lazy(() => import('features/lesson/lesson')),
		},
		{
			path: `${UNIVERSITY}/mentors/find-mentors`,
			exact: true,
			component: lazy(() => import('features/mentor/find-mentors/find-mentors')),
		},
		{
			path: `${UNIVERSITY}/progress-report`,
			exact: true,
			component: lazy(() =>
				import('features/progress-report-list/progress-report-university')
			),
		},
		{
			path: `${UNIVERSITY}/settings`,
			exact: true,
			component: lazy(() => import('features/settings')),
		},
		{
			path: `${UNIVERSITY}/startup-lab-events`,
			exact: true,
			component: lazy(() => import('features/startup-lab-event')),
		},
		{
			path: `${UNIVERSITY}/startup-lab-events/:startupLabEventId`,
			exact: true,
			component: lazy(() => import('features/startup-lab-event-details')),
		},
		{
			component: () => <Redirect to="/errors/error-404" />,
		},
	],
};

export default universityRoutes;
