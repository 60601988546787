export default {
	dashboard: 'Irányítópult',
	onepager: {
		title: 'One-pagerek',
		menuItems: {
			submittedOnepagers: 'Leadott one-pagerek',
			corporateProblems: 'Vállalati problémák',
		},
	},
	news: 'Hírfolyam',
	studentOnePager: 'One-pager',
	modules: 'Modulok',
	moduleList: 'Modullista',
	myProject: 'Projektem',
	pushNotifications: 'Mobil értesítések',
	myTeam: {
		title: 'Csapatom',
		menuItems: {
			team: 'Csapat',
			applications: 'Jelentkezések',
		},
	},
	project: {
		title: 'Projektek',
		menuItems: {
			findProject: 'Projekt kereső',
			applications: 'Jelentkezéseim',
			myProject: 'Projektem',
			mentoredProjects: 'Mentorált projektek',
			mentorInterests: 'Érdeklődések',
			invitations: 'Meghívások',
		},
	},
	mentor: {
		title: 'Mentorok',
		menuItems: {
			findMentors: 'Mentorlista',
			applications: 'Jelentkezéseim',
			mentorInterests: 'Érdeklődések',
		},
	},
	mentors: 'Mentorlista',
	students: 'Hallgatói lista',
	settings: 'Profil beállítások',
	startupLabEvents: 'Események',
	onePager: 'One pager',
	help: 'Segítség',
	progressReport: 'Progress report',
	memberFinder: {
		list: 'Csapattag kereső',
		invitations: 'Meghívások',
	},
};
